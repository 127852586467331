import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  printDiv(divId: string): void {
    const content = document.getElementById(divId)?.innerHTML;
    if (!content) {
      console.error(`Element with id ${divId} not found.`);
      return;
    }

    const printWindow = window.open('', '', 'width=800,height=600');
    if (!printWindow) {
      console.error('Failed to open print window.');
      return;
    }

    printWindow.document.write('<html lang=""><head><title>Print</title>');
    printWindow.document.write(`
        <style>
            @media print {
                @page {
                    margin: 20px;
                    size: auto;
                }
                html {
                    font-family: sans-serif;
                    font-size: 15px;
                }
                small {
                    font-size: 75%;
                }
                table {
                    border-collapse: collapse;
                }
            }
        </style>
    `);
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }
}
