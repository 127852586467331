import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MisService {
  constructor() {
  }

  openNewReferral(misReferralTypeId: number, medicalCaseId: number, orderServiceId: number, callback = null) {
    let callBackUrl = encodeURI(`${environment.apiUrl}callback/referral/select/${orderServiceId}/{id}`);
    let url = `${environment.misUrl}/LisaSK/${misReferralTypeId}?hj=${medicalCaseId}&cb=${callBackUrl}`;
    this.send(url, callback);
  }

  openReferral(misReferralTypeId: number, misReferralId: number, callback = null) {
    let url = `${environment.misUrl}/AvaSK/${misReferralTypeId}/${misReferralId}`;
    this.send(url, callback);
  }

  openPatientInMisReg(misPatientIdCode: string, callback = null) {
    let url = `${environment.misUrl}/avaAken/Registratuur/${misPatientIdCode}`;
    this.send(url, callback);
  }

  public openPatientInMis(misPatientIdCode: number, callback = null) {
    let url = `${environment.misUrl}/patient/${misPatientIdCode}`;
    this.send(url, callback);
  }

  private send(url: string, callback: any){
    url += ((new URL(url).search) ? '&' : '?') + 'ignoreBlockUi=true';

    let xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function () {
      if(callback && this.readyState === 4 && this.status === 200) {
        callback(xhttp.responseText);
      }
    };
    xhttp.open("GET", url, true);
    xhttp.send();
  }
}
