<div class="dept-menu flex gap-3 align-items-center">
  <div class="dept-menu__current flex flex-column gap-1">
    <div class="dept-menu__current-proffesion text-xl uppercase">{{ selectedProfession }}</div>
    <div class="dept-menu__current-department text-sm">{{ selectedDepartment }}</div>
  </div>
  <p-button
    [styleClass]="'dept-menu__btn text-white ' + (isMenuActive ? 'dept-menu__btn--active' : '')"
    [outlined]="true"
    icon="pi pi-chevron-down"
    (click)="toggleMenu($event, op)"
  ></p-button>
</div>

<p-overlayPanel
  #op
  styleClass="dept-menu__overlay w-30rem p-3 flex flex-column gap-2"
  (onHide)="onMenuHide()"
>
  <div
    *ngFor="let dep of departments"
    class="dept-menu__item flex flex-column border-round gap-2 py-3 px-4"
    [ngClass]="{ 'dept-menu__item--active': isSelected(dep) }"
    (click)="changeDepartment(dep.department, dep.profession); toggleMenu($event, op)"
  >
    <div class="dept-menu__item-proffesion text-xl font-semibold">{{ dep.profession }}</div>
    <small class="dept-menu__item-department text-base">{{ dep.department }}</small>
  </div>

  <div class="dept-menu__footer flex justify-content-center mt-2">
    <p-button
      [outlined]="true"
      label="Log out"
      severity="secondary"
      styleClass="dept-menu__logout font-bold"
      [icon]="'pi pi-sign-out'"
      iconPos="right"
      (click)="logout()"
    ></p-button>
  </div>
</p-overlayPanel>
