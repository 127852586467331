import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DateTime',
  standalone: true
})
export class DateTimePipe implements PipeTransform {
  transform(datetime: string | null): string {
    if (!datetime) return '';

    return moment(datetime).format('DD.MM.YYYY HH:mm');
  }
}
