import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { SplitButtonModule } from "primeng/splitbutton";
import { MenuModule } from "primeng/menu";
import { MenuItem } from "primeng/api";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IService } from "@core/interfaces/service.interface";
import { OrderTypesService } from "@core/services/order-types.service";
import { ListService } from "@core/services/list.service";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IOrderServicesData } from "@core/interfaces/order-services-data.interface";
import { IOrder } from "@core/interfaces/order.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { OrderReferralStatus } from "@core/enums/order-referral-status.enum";
import { ModalService } from "@core/services/modal.service";
import { TableHistoryComponent } from "@shared/modals/table-history/table-history.component";
import { ModalSize } from "@core/enums/modalSize";
import { HttpParams } from "@angular/common/http";
import { OrderService } from "@core/services/order.service";
import { forkJoin } from "rxjs";
import { IOrderServiceLog } from "@core/interfaces/order-service-log.interface";
import { OrderServiceLogStatus } from "@core/enums/order-service-log-status.enum";
import { RosterService } from "@core/services/roster.service";
import { SstService } from "@core/services/sst.service";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";
import { OrderServiceService } from "@core/services/order-service.service";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";
import { ConfirmModalComponent } from "@shared/modals/confirm/confirm.modal.component";
import { ToastService } from "@core/services/toast.service";
import { OrderServiceQueueService } from "@core/services/order-service-queue.service";
import { OrderServiceTimeService } from "@core/services/order-service-time.service";
import { IReason } from "@core/interfaces/reason.interface";
import { LogService } from "@core/services/log.service";
import { Router } from "@angular/router";
import { DashboardService } from "@core/services/dashboard.service";

@Component({
  selector: 'medis-order-actions',
  standalone: true,
  imports: [
    ButtonModule,
    SplitButtonModule,
    MenuModule,
    OverlayPanelModule,
  ],
  templateUrl: './order-actions.component.html'
})
export class OrderActionsComponent implements OnInit {
  @Input() time!: IOrderServiceTime;
  @Input() service!: IService;
  @Input() orderService!: IOrderService;
  @Input() orderServiceData!: IOrderServicesData;
  @Input() order!: IOrder;
  @Input() futureOrders: boolean = false;

  @Output() onTimeCancel = new EventEmitter();

  orderTypes: any = [];
  actions: MenuItem[] | undefined;
  orderServiceStatus: OrderServiceStatus | null | undefined;
  patientIdCode!: string;
  patientMisCode!: number | null;
  nextOrderId!: number | null | undefined;
  messages: string[] = [];

  constructor(
    private orderTypesService: OrderTypesService,
    private listService: ListService,
    private modalService: ModalService,
    private orderServiceService: OrderServiceService,
    private _orderService: OrderService,
    private orderServiceQueueService: OrderServiceQueueService,
    private orderServiceTimeService: OrderServiceTimeService,
    private logService: LogService,
    private rosterService: RosterService,
    private sstService: SstService,
    private externalMedicalCaseService: ExternalMedicalCaseService,
    private toastService: ToastService,
    private router: Router,
    private dashboardService: DashboardService,
  ) {
  }

  ngOnInit() {
    this.orderTypes = this.orderTypesService.getOrderTypes(this.listService.listCodesMap.value);
    this.orderServiceStatus = this.getOrderServiceStatus();
    this.patientIdCode = this.order?.patient?.idCode ?? this.time.patientIDcode;
    this.patientMisCode = this.order?.patient?.misCode ?? this.time.patientMisCode;
    this.nextOrderId = this.time?.nextOrderId ?? this.order?.nextOrderId;
    this.initActions();
  }

  private initActions(): void {
    this.actions = [
      { label: 'Ava tellimus', visible: true },
      { label: 'Prindi', visible: this.printActionVisible() },
      { label: 'Kommentaarid', visible: true, command: () => this.orderServiceService.openCommentsByOrder(this.time) },
      {
        label: this.orderServiceStatus === OrderServiceStatus.PREPARING ? 'Broneeri aeg' : 'Muuda aeg',
        visible: this.bookTimeActionVisible()
      },
      { label: 'Ava saatekirjad', visible: this.openReferralsActionVisible() },
      {
        label: 'Tühista aeg',
        visible: this.cancelTimeActionVisible(),
        command: () => this.cancelTime()
      },
      { label: 'Koosta arve', visible: this.orderServiceStatus === OrderServiceStatus.PRESENT },
      { label: 'Ava MIS reg', visible: !!this.patientIdCode },
      { label: 'Ava MISis', visible: !!this.patientMisCode },
      { label: 'Ava Labor', visible: this.openReferralLaborVisible() },
      { label: 'Ava SST', visible: true, command: () => {
          this.sendSSTMessage()
        }
      },
      {
        label: 'Lõpetatud',
        visible: this.orderServiceStatus !== OrderServiceStatus.FINISHED,
        command: () => this.setFinished()
      },
      {
        label: 'Tühista kohal',
        visible: this.orderServiceStatus === OrderServiceStatus.PRESENT,
        command: () => this.cancelPresent()
      },
      { label: 'Kuva ajalugu', visible: true, command: () => this.showPatientReservationHistory()},
      {
        label: this.nextOrderId ? 'Ava külastuse tellimus' : 'Lisa uus tellimus',
        visible: this.orderServiceStatus === OrderServiceStatus.PRESENT,
      },
      { label: 'Vabasta saatekiri', visible: this.releaseReferralVisible() }
    ];
  }

  private getOrderServiceStatus(): OrderServiceStatus | null | undefined {
    return this.time.orderServiceTimeStatus || this.time.orderServiceQueueStatus || this.time.orderServiceStatus;
  }

  private printActionVisible(): boolean {
    const statuses = [OrderServiceStatus.CANCELLED, OrderServiceStatus.FINISHED, OrderServiceStatus.ABSENCE];

    return (
      !!this.orderServiceData &&
      !!this.orderServiceData.orderServiceTimes &&
      this.orderServiceData.orderServiceTimes.length > 0 &&
      this.orderServiceData.orderServiceTimes.some(ost => ost.orderServiceTimeStatus && !statuses.includes(ost.orderServiceTimeStatus))
    ) || (
      !!this.orderServiceData &&
      !!this.orderServiceData.orderServiceQueues &&
      this.orderServiceData.orderServiceQueues.length > 0 &&
      this.orderServiceData.orderServiceQueues.some(osq => osq.orderServiceTimeStatus && !statuses.includes(osq.orderServiceTimeStatus))
    );
  }

  private bookTimeActionVisible(): boolean {
    const todayDate = new Date();
    const currentTime = todayDate.toTimeString().split(' ')[0];
    const bookingDate = new Date(this.time.date);
    const bookingTimeStart = this.time.timeFrom;
    const statuses = [OrderServiceStatus.CONFIRMED, OrderServiceStatus.IN_PROVIDER_QUEUE, OrderServiceStatus.PREPARING, OrderServiceStatus.ABSENCE];
    const isSameDay = todayDate.toDateString() === bookingDate.toDateString();
    const bookingInFuture = isSameDay ? currentTime < bookingTimeStart : todayDate < bookingDate;

    return !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus) && bookingInFuture;
  }

  private openReferralsActionVisible(): boolean {
    const statuses = [
      OrderServiceStatus.CONFIRMED,
      OrderServiceStatus.PREPARING,
      OrderServiceStatus.PRESENT,
      OrderServiceStatus.IN_PROVIDER_QUEUE,
      OrderServiceStatus.IN_SERVICE_QUEUE,
      OrderServiceStatus.BOOKING_NOT_NEEDED,
      OrderServiceStatus.FINISHED
    ];
    return !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus);
  }

  private cancelTimeActionVisible(): boolean {
    const statuses = [
      OrderServiceStatus.CONFIRMED,
      OrderServiceStatus.PREPARING,
      OrderServiceStatus.IN_PROVIDER_QUEUE,
      OrderServiceStatus.IN_SERVICE_QUEUE
    ];

    return !!this.time && !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus);
  }

  private openReferralLaborVisible(): boolean {
    return !!this.service && !!this.service.referralType && this.orderService.referralStatus === OrderReferralStatus.CONFIRMED && !!this.orderService.referralId;
  }

  private releaseReferralVisible(): boolean {
    if (!this.time) return false;

    const timeParts = this.time.timeUpto.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    const date = new Date(this.time.date);
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);
    date.setSeconds(date.getSeconds() + seconds);

    return date < new Date() && this.orderServiceStatus === OrderServiceStatus.CONFIRMED && this.orderService?.referralId !== null;
  }

  private filterInvoiceLogs(logs: IOrderServiceLog[]): IOrderServiceLog[] {
    return logs.filter(log => log.statusField === this.OrderServiceLogStatus.INVOICE_STATUS);
  }

  private filterOrderServiceLogs(logs: IOrderServiceLog[]): IOrderServiceLog[] {
    return logs.filter(log => log.statusField === this.OrderServiceLogStatus.STATUS || log.statusField === this.OrderServiceLogStatus.REFERRAL_STATUS);
  }

  showPatientReservationHistory() {
    forkJoin({
      orderServiceLogs: this.logService.getOrderServiceLogs(this.time.orderServiceId),
      orderServiceNotificationLogs: this.logService.getOrderServiceNotificationLogs(this.time.orderServiceId)
    }).subscribe({
      next: (response) => {
        const orderServiceLogs = this.filterOrderServiceLogs(response.orderServiceLogs);
        const orderServiceInvoiceLogs = this.filterInvoiceLogs(response.orderServiceLogs);
        const orderServiceNotificationLogs = Array.isArray(response.orderServiceNotificationLogs) ? response.orderServiceNotificationLogs : [response.orderServiceNotificationLogs];
        this.modalService.openModal({
          component: TableHistoryComponent,
          size: ModalSize.MEDIUM,
          data: {
            orderServiceLogs,
            orderServiceInvoiceLogs,
            orderServiceNotificationLogs,
          },
          header: `${this.time.serviceName} - staatuse ajalugu (tellimuse nr. ${this.time.orderId})`
        });
      },
      error: (e: Error) => {
        console.error(e);
      }
    });
  }

  protected readonly OrderServiceLogStatus = OrderServiceLogStatus;

  private sendSSTMessage(): void {
    let httpParams = new HttpParams();

    httpParams.set("providerId", this.time.providerId);
    httpParams.set("timeFrom", this.time.timeFrom);
    httpParams.set("date", this.time.date);
    this.rosterService
      .getPlanTimeByTime(httpParams)
      .subscribe({
        next: (response) => {
          this.sstService.open({
            patientId: this.time.patientId,
            patientCode: this.time.patientIDcode,
            providerId: this.time.providerId,
            providerCode: this.time.providerCode,
            departmentId: response[0].departmentId,
            departmentName: response[0].departmentName
          })
        }
      });
  }

  private sendOpenPatientMessage(): void {
    let httpParams = new HttpParams();
    httpParams.set("orderServiceTimeId", this.time.orderServiceTimeId || 0);
    httpParams.set("orderServiceQueueId", this.time.orderServiceQueueId || 0);
    this._orderService
      .getInitExternalMedicalCaseData(httpParams)
      .subscribe({
        next: (response) => {
          this.externalMedicalCaseService.open(response);
        }
      });
  }

  private cancelTime(): void {
    const invoiceCompletedStatuses = [InvoiceStatus.COMPLETED, InvoiceStatus.PREPAID];
    const CONFIRMATION_MODAL_TITLE = 'Tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Teenuse eest on tasutud, kas olete kindel, et soovite broneeringu koos arvega tühistada? Broneeringu aja muutmisel arvet ei tühistata.'

    if (this.order?.invoiceStatus && invoiceCompletedStatuses.includes(this.order.invoiceStatus)) {
      const ref = this.modalService.openModal({
        component: ConfirmModalComponent,
        size: ModalSize.SMALL,
        header: CONFIRMATION_MODAL_TITLE,
        data: { bodyText: CONFIRMATION_MODAL_BODY_TEXT }
      })

      ref.onClose.subscribe((confirmed: boolean | string) => {
        if (confirmed) {
          this.openCancellationConfirmationModal();
        }
      })
    } else {
      this.openCancellationConfirmationModal();
    }
  }

  private openCancellationConfirmationModal(): void {
    const CONFIRMATION_MODAL_TITLE = 'Tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid tühistada? Kui Jah, siis sisesta põhjus'

    const ref = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: {
        bodyText: CONFIRMATION_MODAL_BODY_TEXT,
        showReason: true,
        showReasonOptions: this.time?.service ? this.time.service.isRehabilitation : this.time.isRehabilitation,
      }
    })

    ref.onClose.subscribe((confirmation: any) => {
      if (confirmation) {
        const request = this.time.orderServiceQueueId
          ? (id: number, data: IReason) => this.orderServiceQueueService.cancelOrderServiceTimeQueue(id, data)
          : (id: number, data: IReason) => this.orderServiceTimeService.cancelOrderServiceTime(id, data);

        request(this.time.orderServiceQueueId ?? this.time.orderServiceTimeId, {
          reason: confirmation?.reason ?? null,
          reasonOption: confirmation?.reasonOption ?? null
        }).subscribe({
          next: () => {
            this.onTimeCancel.emit({ time: this.time, status: OrderServiceStatus.CANCELLED });
          },
          error: (err) => {
            this.toastService.error(err.message);
          }
        })
      }
    })
  };

  private setFinished(): void {
    const CONFIRMATION_MODAL_TITLE = 'Lõpetatamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid lõpetada? Kui Jah, siis sisesta põhjus.'

    const ref = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: { bodyText: CONFIRMATION_MODAL_BODY_TEXT, showReason: true }
    })

    ref.onClose.subscribe((confirmed: any) => {
      if (confirmed) {
        let orderService: any = {
          orderServiceStatus: OrderServiceStatus.FINISHED
        }

        if (confirmed.reasonOption) orderService.reasonOption = confirmed.reasonOption;
        if (confirmed.reason) orderService.reason = confirmed.reason;

        this.dashboardService.setOrderServiceStatus(this.time.orderServiceId, orderService).subscribe({
          next: () => {
            this.onTimeCancel.emit({ time: this.time, status: OrderServiceStatus.FINISHED });
          },
          error: (err) => {
            this.toastService.error(err.message);
          }
        });
      }
    })
  }

  cancelPresent() {
    const CONFIRMATION_MODAL_TITLE = 'Kohal tühistamine';
    const CONFIRMATION_MODAL_BODY_TEXT = 'Kas oled kindel, et soovid "Kohal" tühistada?'

    const modalRef = this.modalService.openModal( {
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: CONFIRMATION_MODAL_TITLE,
      data: { bodyText: CONFIRMATION_MODAL_BODY_TEXT, showReason: true }
    })

    modalRef.onClose.subscribe((resp: any) => {
    if (resp) {
      if (this.time.orderServiceTimeId) {
        this.dashboardService
          .cancelOrderServiceTimePresentStatus(this.time.orderServiceTimeId, { reason: resp?.reason ?? '' })
          .subscribe({
            next: () => {
              this.onTimeCancel.emit({ time: this.time, status: OrderServiceStatus.CONFIRMED });
            },
            error: (err) => {
              this.toastService.error(err.message);
            }
          })
      }
      if (this.time.orderServiceQueueId) {
        this.dashboardService
          .cancelOrderServiceQueuePresentStatus(this.time.orderServiceQueueId, { reason: resp?.reason ?? '' })
          .subscribe({
            next: () => {
              this.onTimeCancel.emit({ time: this.time, status: OrderServiceStatus.CONFIRMED });
            },
            error: (err) => {
              this.toastService.error(err.message);
            }
          })
      }
    }
    })
  }
}
