import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { Router } from "@angular/router";

@Component({
  selector: 'app-single-patient-search',
  templateUrl: './single-patient-search.component.html'
})
export class SinglePatientSearchComponent {
  @Input() data!: IPatient;
  @Input() showActions: boolean = true;
  @Output() clearSearch = new EventEmitter<void>();

  constructor(private router: Router) {
  }

  onClearSearch() {
    this.clearSearch.emit();
  }

  showPatientBooking(patient: IPatient){
    this.router.navigate(['/reservation'], { state: { patient } });
  }

  getStatusInfo(isInsured: boolean) {
    return isInsured
      ? { class: 'tag--green', name: 'KINDLUSTATUD' }
      : { class: 'tag--red', name: 'KINDLUSTAMATA' };
  }
}
