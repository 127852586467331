import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {
  public convertDateToNgbDate(date: Date): NgbDate | null {
    if (!date) {
      return null;
    }
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  public getCurrentYear(): number {
    return moment().year();
  }

  public getPreviousYear(): number {
    return moment().subtract(1, 'year').year();
  }

  public convertNgbDateToDate(ngbDate: NgbDate): Date | null {
    if (!ngbDate) {
      return null;
    }
    return moment(ngbDate.year + '-' + ngbDate.month + '-' + ngbDate.day, 'YYYY-MM-DD').toDate();
  }

  public convertNgbDateToString(ngbDate: NgbDate): string | null {
    if (!ngbDate) {
      return null;
    }
    return moment(ngbDate.year + '-' + ngbDate.month + '-' + ngbDate.day).format('YYYY-MM-DD');
  }

  public convertDateToString(date: Date, dateFormat: string): string | null {
    if (!date) {
      return null;
    }
    return moment(date).format(dateFormat);
  }

  public isToday(ngbDateStruct: NgbDateStruct): boolean {
    const ngbDate = new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day);
    return ngbDate.equals(this.convertDateToNgbDate(new Date()));
  }

  public convertNgbDateAndNgbTimeToDate(ngbDate: NgbDate, ngbTime: NgbTimeStruct ): Date | null {
    if (!ngbDate) {
      return null;
    }

    return moment(ngbDate.year + '-' + ngbDate.month + '-' + ngbDate.day + ' ' +
      (ngbTime?.hour ?? 0) + ':' + (ngbTime?.minute ?? 0) + ':' + (ngbTime?.second ?? 0), 'YYYY-MM-DD HH:mm:ss').toDate();
  }

  public convertDateToNgbTime(date: Date): NgbTimeStruct | null {
    if (!date) {
      return null;
    }
    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
  }
}
