import { Component, Input, OnInit } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { FullSexPipe } from "@shared/pipes/full-sex.pipe";
import { PersonAddressPipe } from "@shared/pipes/person-address.pipe";

@Component({
  selector: 'medis-order-patient',
  templateUrl: './order-patient.component.html',
  styleUrl: './order-patient.component.scss'
})
export class OrderPatientComponent implements OnInit {
  @Input() patient!: IPatient;

  userDetails: any;

  constructor(
    private genderPipe: FullSexPipe,
    private addressPipe: PersonAddressPipe,
    ) {
  }

  ngOnInit() {
    if (this.patient) {
      this.initializeUserDetails();
    }
  }

  initializeUserDetails(): void {
    this.userDetails = {
      leftInfo: [
        { label: 'Eesnimi', value: this.patient?.firstname },
        { label: 'Perekonnanimi', value: this.patient?.lastname },
        { label: 'Isikukood', value: this.patient?.idCode },
        { label: 'Sugu', value: this.genderPipe.transform(this.patient?.gender) },
      ],
      rightInfo: [
        { label: 'Telefon', value: this.patient?.phone },
        { label: 'E-post', value: this.patient?.email },
        { label: 'Aadress', value: this.addressPipe.transform(this.patient?.contact) },
      ]
    };
  }
}
