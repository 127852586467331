<div class="flex flex-column gap-4 overflow-y-auto">
  <div id="printSection">
    @if (printData.length) {
      <div class="mt-4">
        <p-table styleClass="p-datatable--round" [value]="printData">
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th>Aeg</th>
              <th>Patsient</th>
              <th>Teenus</th>
              <th>Telefon</th>
              <th>Kommentaar</th>
              <th>Maksja</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
              <tr>
                  <td>
                    <medis-order-service-status-label
                      [status]="row.orderServiceTimeStatus ?? row.orderServiceStatus"
                      [isShort]="true"></medis-order-service-status-label>
                  </td>
                @if (row.state && !isOrderServiceTimesData) {
                  <td>
                    @if (row.state === BookingTimeState.BOOKED) {
                      <p-tag
                        rounded="true"
                        value="B"
                        styleClass="tag tag--cyan"
                        pTooltip="Broneeritud"
                        tooltipPosition="bottom"></p-tag>
                    }
                    @if (row.state === BookingTimeState.ABSENCE) {
                      <p-tag
                        rounded="true"
                        value="A"
                        styleClass="tag tag--blue"
                        pTooltip="Annuleeritud"
                        tooltipPosition="bottom"></p-tag>
                    }
                  </td>
                }
                <td>{{  row.range }}</td>
                <td>
                  <div>
                    {{  row.patientName }}
                  </div>
                  <small>{{  row.patientIDcode ?  row.patientIDcode :  row.patientCode }}</small>
                </td>
                <td class="service-name">
                  <medis-service-badge [badges]="row.serviceBadges"></medis-service-badge>
                  {{ row.serviceName }}&nbsp;
                  @if (row.complexServiceId) {
                    <div class="text-muted">
                      {{  row.complexServiceName }}
                      <medis-service-type-label [type]="row.complexServiceComplexType"></medis-service-type-label>
                    </div>
                  }
                </td>
                <td nowrap>{{  row.patientPhone }}</td>
                <td>
                  @if (row.comment) {
                    <span>{{ row.comment }}</span>
                  }
                </td>
                <td>
                  <medis-financial-source-label [financialSource]=" row.financialSourceName"></medis-financial-source-label>
                </td>
              </tr>
          </ng-template>
        </p-table>
      </div>
    }
  </div>
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button label="Prindi" severity="contrast" (click)="print()"/>
  <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
</div>
