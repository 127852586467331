export enum InvoiceStatus {
  REQUIRED = "REQUIRED",
  PAYMENT_LINK_SENT = "PAYMENT_LINK_SENT",
  CREATED = "CREATED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  PREPAID = "PREPAID",
  REFUND = "REFUND",
  PREPAID_FAILED = "PREPAID_FAILED"
}
