import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbModule } from "primeng/breadcrumb";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'medis-breadcrumb',
  standalone: true,
  imports: [
    BreadcrumbModule
  ],
  template: `<p-breadcrumb [model]="breadcrumbs" [home]="home"></p-breadcrumb>`
})
export class BreadcrumbComponent implements OnInit {
  private readonly BREADCRUMB_TARGET = '_self'

  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };
  breadcrumbs!: MenuItem[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.cdRef.detectChanges();
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const routeURL: string = route.snapshot.url.map(segment => segment.path).join('/');
    if (routeURL !== '') {
      url += `/${routeURL}`;
    }

    const label = route.snapshot.data['breadcrumb'];
    if (label) {
      breadcrumbs.push({
        label,
        url,
        target: this.BREADCRUMB_TARGET,
        disabled: route.children.length === 0,
      });
    }

    if (route.firstChild) {
      return this.createBreadcrumbs(route.firstChild, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
