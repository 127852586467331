import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { EConsultationsTodayComponent } from './components/e-consultations-today/e-consultations-today.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { DateTimeDisplayPipe } from "./pipes/date-time-display.pipe";
import { ServiceBadgeComponent } from "./components/service-badge/service-badge.component";
import { ServiceTypeLabelComponent } from "./components/service-type-label/service-type-label.component";
import { ComplexPartsPopoverComponent } from "./components/service-parts-popover/complex-parts-popover.component";
import { ProviderInfoPopoverComponent } from "./components/provider-info-popover/provider-info-popover.component";
import { ColleagueInfoPopoverComponent } from "./components/collegue-info-popover/colleague-info-popover.component";
import { FinancialSourceLabelComponent } from "./components/financial-source-label/financial-source-label.component";
import { ChannelNamePipe } from "./pipes/channel-name.pipe";
import {
  OrderServiceStatusLabelComponent
} from "./components/order-service-status-label/order-service-status-label.component";
import { OrderActionsComponent } from "./components/order-actions/order-actions.component";
import { BadgeModule } from "primeng/badge";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ButtonModule } from "primeng/button";
import { DividerModule } from "primeng/divider";
import { InputTextModule } from "primeng/inputtext";
import { MenubarModule } from "primeng/menubar";
import { PasswordModule } from "primeng/password";
import { InputMaskModule } from "primeng/inputmask";
import { ButtonGroupModule } from "primeng/buttongroup";
import { TabMenuModule } from "primeng/tabmenu";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { PatientsTableComponent } from "../feature/office/patients-table/patients-table.component";
import { TableModule } from "primeng/table";
import { ChipsModule } from "primeng/chips";
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { SidebarModule } from 'primeng/sidebar';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { PrintPatientsModalComponent } from "@shared/modals/print-patients/print-patients.modal.component";
import { OrderStatusPipe } from "@shared/pipes/order-status.pipe";
import { PriceSelectListComponent } from './components/price-select-list/price-select-list.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { BreadcrumbComponent } from "@shared/components/breadcrumb/breadcrumb.component";
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DeptMenuComponent } from "@shared/components/dept-menu/dept-menu.component";

@NgModule({
  declarations: [
    EConsultationsTodayComponent,
    ServiceBadgeComponent,
    PatientsTableComponent,
    PrintPatientsModalComponent,
    PriceSelectListComponent,
    PageHeaderComponent,
    DeptMenuComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        NgSelectModule,
        DateTimeDisplayPipe,
        ServiceTypeLabelComponent,
        ComplexPartsPopoverComponent,
        ProviderInfoPopoverComponent,
        ColleagueInfoPopoverComponent,
        FinancialSourceLabelComponent,
        ChannelNamePipe,
        OrderServiceStatusLabelComponent,
        OrderActionsComponent,
        ButtonModule,
        DropdownModule,
        CalendarModule,
        ButtonGroupModule,
        TableModule,
        ChipsModule,
        IconFieldModule,
        InputIconModule,
        MenuModule,
        SelectButtonModule,
        TagModule,
        ChipModule,
        BadgeModule,
        SidebarModule,
        FloatLabelModule,
        TooltipModule,
        OverlayPanelModule,
        ToastModule,
        TabMenuModule,
        DialogModule,
        InputTextareaModule,
        MessagesModule,
        OrderStatusPipe,
        BreadcrumbModule,
        InputGroupModule,
        InputGroupAddonModule,
        BreadcrumbComponent,
    ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    EConsultationsTodayComponent,
    NgSelectModule,
    ProgressSpinnerModule,
    ButtonModule,
    ButtonGroupModule,
    DividerModule,
    InputTextModule,
    MenubarModule,
    PasswordModule,
    InputMaskModule,
    BadgeModule,
    TabMenuModule,
    TableModule,
    IconFieldModule,
    InputIconModule,
    MenuModule,
    DropdownModule,
    CalendarModule,
    SelectButtonModule,
    TagModule,
    ChipModule,
    SidebarModule,
    FloatLabelModule,
    TooltipModule,
    OverlayPanelModule,
    ToastModule,
    DialogModule,
    InputTextareaModule,
    MessagesModule,
    ServiceBadgeComponent,
    PatientsTableComponent,
    BreadcrumbModule,
    InputGroupModule,
    InputGroupAddonModule,
    PriceSelectListComponent,
    PageHeaderComponent,
    DeptMenuComponent,
  ]
})
export class SharedModule {
}
