@if(data){
  <div class="flex flex-row w-full gap-4" style="height: 80.5px; padding: 17.5px 24.5px 17.5px 24.5px">

    <div class="single-patient__content flex flex-row w-full gap-4">

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">PATSIENT</div>
        <div class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.fullName }} | {{ data.idCode }}</div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">TELEFON</div>
        <div class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.phone }}</div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">PEREARST</div>
        <div class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.doctorName }}</div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">ESMANE KONTAKT</div>
        <div class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.gpTeamPrimaryProviderName }}</div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">KINDLUSTATUD</div>
        <div class="single-patient__value flex-grow-1">
          <p-tag
            [styleClass]="'white-space-nowrap tag tag--small ' + (getStatusInfo(data.isInsured).class)"
            rounded="true"
            [value]="getStatusInfo(data.isInsured).name">
          </p-tag>
        </div>
      </div>

    </div>

    <div class="single-patient__actions flex flex-grow-1 justify-content-end w-18rem gap-4">
      @if(!showActions){
        <p-button
          [outlined]="true"
          severity="secondary"
          [style]="{ height: '2.5rem' }"
          styleClass="flex-grow-1 w-15rem">
          <b>Tulevased broneeringud (n)</b>
        </p-button>
        <p-button
          [outlined]="true"
          severity="secondary"
          [style]="{ height: '2.5rem', width: '2.5rem' }"
          icon="pi pi-times"
          (click)="onClearSearch()">
        </p-button>
      }
      @if(showActions){
        <p-button
          [outlined]="true"
          severity="secondary"
          [style]="{ height: '2.5rem' }"
          styleClass="flex-grow-1"
          (click)="showPatientBooking(data)">
          <b>Broneeri aeg</b>
        </p-button>
        <p-button
          [outlined]="true"
          severity="secondary"
          [style]="{ height: '2.5rem', width: '2.5rem' }"
          icon="pi pi-ellipsis-h"
          (click)="onClearSearch()">
        </p-button>
      }
    </div>

  </div>

}
