import { Component, Input } from '@angular/core';
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'medis-service-parts-popover',
  standalone: true,
  imports: [
    NgbPopover
  ],
  templateUrl: './complex-parts-popover.component.html',
})
export class ComplexPartsPopoverComponent {
  @Input() serviceId!: number;
  @Input() serviceType!: any;
}
