@if (data.length) {
  <p-table [value]="data" class="table table--today-plan">
    <ng-template pTemplate="header">
      <tr>
        <th>Aeg</th>
        <th>Asukoht</th>
        <th>Patsient</th>
        <th>Teenus</th>
        <th>Osutaja</th>
        <th>Maksja</th>
        <th>Kanal</th>
        <th>Staatus</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-time>
      <tr [class.row--highlight]="(time.orderServiceTimeStatus ?? time.orderServiceStatus) === OrderServiceStatus.PRESENT">
        <td>
          {{ time.range }}
        </td>
        <td>
          {{ time.departmentShort }} {{ time.roomCode }}
        </td>
        <td>
          <div class="flex flex-column gap-1">
            <a class="text-primary patient-link" routerLink="/clients/{{time.patientId}}/details">
              {{ convertNameToProperCase(time.patientName) }}
            </a>
            <span class="text-sm font-semibold">{{ time.patientIDcode }}</span>
          </div>
        </td>
        <td>
          <div class="flex gap-2 align-items-center">
            <medis-service-badge [badges]="time.serviceBadges"></medis-service-badge>
                      {{ time.serviceName }}&nbsp;
          @if (time.commentsCount && time.commentsCount > 0) {
            <p-button [rounded]="true"
                      icon="pi pi-comment"
                      [text]="true"
                      severity="secondary"
                      (click)="openCommentsByOrder(time)"/>
          }
          @if (time.complexServiceId) {
            <div class="text-muted">
              {{ time.complexServiceName }}
              <medis-service-type-label [type]="time.complexServiceComplexType"></medis-service-type-label>
              <!--              <medis-service-parts-popover [serviceId]="time.complexServiceId"-->
              <!--                                           [serviceType]="time.complexServiceComplexType"></medis-service-parts-popover>-->
            </div>
          }
          </div>
        </td>
        <td>
          {{ time.providerName }}
          <!--          @if (time.providerName) {-->
          <!--            <medis-provider-info-popover [orderService]="time"></medis-provider-info-popover>-->
          <!--          }-->
          <!--          @if (time.relatedPersonId) {-->
          <!--            <medis-colleague-info-popover [orderService]="time"></medis-colleague-info-popover>-->
          <!--          }-->
        </td>
        <td>
          <medis-financial-source-label [financialSource]="time.financialSourceName"></medis-financial-source-label>
        </td>
        <td>{{ time.channelName | channelName }}</td>
        <td style="width: 200px;">
          @if (time.orderServiceTimeStatus || time.orderServiceStatus) {
              <medis-order-service-status-label [status]="time.orderServiceTimeStatus ?? time.orderServiceStatus"
                                                [invoiceStatus]="time.invoiceStatus">
              </medis-order-service-status-label>
          }
        </td>
        <td style="width: 200px;">
          <div class="table__actions flex justify-content-end gap-3">
          @if (time.state !== 'Free') {
            @if (presentReady(time) || statusPresent(time)) {
              <p-button [label]="presentReady(time) ? 'Kohal' : 'Ava patsient'"
                        (onClick)="presentReady(time) ? setPresent(time) : showPatient(time)"
                        severity="secondary"
                        styleClass="w-full w-9rem font-bold py-2"
                        [outlined]="true"></p-button>
            }
          } @else {
            <p-button label="Broneeri"
                      severity="secondary"
                      styleClass="w-full w-9rem font-bold py-2">
            </p-button>
          }
                    @if (time.state !== 'Free') {
            <medis-order-actions [time]="time" [futureOrders]="isFutureDateSelected()"></medis-order-actions>
            <!--                                  <div class="btn-group w-75" uib-dropdown>-->
              <!--                                      <order-actions-->
              <!--                                        order="orderService"-->
              <!--                                        order-service="orderService"-->
              <!--                                        order-service-qt="orderService"-->
              <!--                                        on-action-complete="refresh()"-->
              <!--                                        are-future-orders="isFutureDateSelected()"-->
              <!--                                      ></order-actions>-->
              <!--                                    </div>-->
          }
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
