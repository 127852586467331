import { Pipe, PipeTransform } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";
import { ServiceType } from "@core/enums/service-type";

@Pipe({
  name: 'serviceType',
  standalone: true
})
export class ServiceTypePipe implements PipeTransform {
  private statusMap: IMapping = {
    [ServiceType.COMBO_SERVICE]: 'Teenusepakett',
    [ServiceType.FULL_SERVICE]: 'Kompleksteenus',
    [ServiceType.IN_COMBO_ONLY]: 'Kuulub ainult paketti',
  };

  transform(type: any, withParenthesis: boolean = false): string {
    const typeName = this.statusMap[type];
    if (typeName) {
      return withParenthesis ? `(${typeName})` : typeName;
    }
    return '';
  }

}
