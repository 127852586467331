import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";
import { ICurrentUser } from '../interfaces/current-user.interface';
import { environment } from '../../../environments/environment';
import { IUser } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly BACKEND_URL: string;
  readonly currentUserSubject = new BehaviorSubject<ICurrentUser | null>(null);

  constructor(private http: HttpClient,
              private router: Router,) {
    this.BACKEND_URL = `${environment.backendUrl}/auth`;
  }

  private addAccessTokenToLocalStorage(token: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, token);
  }

  getAccessToken(): any {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  hasAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  removeAccessToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
  }

  start(credentials: IUser): Observable<string> {
    return this.http.post<string>(`${this.BACKEND_URL}/login`, credentials);
  }

  getCurrentUser(): Observable<ICurrentUser|null> {
    if(this.currentUserSubject.value != null) {
      return this.currentUserSubject.pipe();
    }
    return this.http.get<ICurrentUser>(`${this.BACKEND_URL}/user`);
  }

  getLogoutUrl(): Observable<string> {
    return this.http.get<string>(`${this.BACKEND_URL}/getLogoutUrl`);
  }

  refreshAccessToken(): Observable<string> {
    return this.http.get<string>(`${this.BACKEND_URL}/refreshAccessToken`);
  }

  getToken(state: string, code: string): Observable<string> {
    const params = {code, state};
    return this.http.get<string>(`${this.BACKEND_URL}/getToken`, {params});
  }

  handleUserLogin(token: string): void {
    this.addAccessTokenToLocalStorage(token);
    this.setCurrentUserToSubject();
    this.router.navigate(['/office']);
  }

  logout(): void {
    this.removeAccessToken();
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }

  setCurrentUserToSubject(): void {
    this.getCurrentUser().subscribe({
      next: (response) => {
        if (response) {
          this.currentUserSubject.next(response);
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  private getCurrentUserFromAccessToken(): ICurrentUser | null {
    if (!this.hasAccessToken()) {
      return null;
    }
    const token = this.getAccessToken();

    return jwtDecode<ICurrentUser>(token);
  }
}
