<ng-template #popContent>
  <label class="col-sm-4 control-label">Telefon</label>
  <div class="col-sm-8">
    {{ provider.mobile }}
  </div>
</ng-template
>
<ng-template #popTitle>Osutaja - {{ provider.providerName }}</ng-template>
<button type="button" class="btn btn-link btn-sm btn-outline-secondary" [ngbPopover]="popContent" [popoverTitle]="popTitle">
  <i class="bi bi-info-lg"></i>
</button>
