import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { KeyValuePair } from "../interfaces/key-value-pair.interface";
import { IPerson } from "../interfaces/person.interface";
import { IOrderServicesData } from "../interfaces/order-services-data.interface";
import { IOrderServiceTime } from "../interfaces/order-service-time.interface";
import {DailyPlanComponent} from "../../feature/office/daily-plan/daily-plan.component";
import {
  EConsultationsTodayComponent
} from "@shared/components/e-consultations-today/e-consultations-today.component";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/dashboard`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/dashboard`;
  }

  public getUserDashboardBlocks(type: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.BACKEND_URL}/blocks/${type}`);
  }

  public getBlocksOrdersCount(params: any): Observable<KeyValuePair<number, number>[]> {
    return this.http.get<KeyValuePair<number, number>[]>(`${this.BACKEND_URL}/blocks/ordersCount`, {params});
  }

  public getProvidersByDate(date: string | null): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers/${date}`);
  }

  public getDashboardProviders(): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers`);
  }

  public getOrderServicesByDate(date: string | null, providerId: number, params: any): Observable<IOrderServicesData> {
    return this.http.get<IOrderServicesData>(`${this.BACKEND_URL}/bookings/${date}/${providerId}`, {params});
  }

  public setOrderServiceStatus(orderServiceId: number, orderService: IOrderServiceTime): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/orderservice/${orderServiceId}/status`, orderService);
  }

  public cancelOrderServiceTimePresentStatus(orderServiceTimeId: number, reasonData: any): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/orderservicetime/${orderServiceTimeId}/cancelpresent`, reasonData);
  }

  public cancelOrderServiceQueuePresentStatus(orderServiceQueueId: number, reasonData: any): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/orderservicequeue/${orderServiceQueueId}/cancelpresent`, reasonData);
  }

  public setOrderServiceTimeStatus(orderServiceTimeId: number, orderServiceStatus: any): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/orderservicetime/${orderServiceTimeId}/status`, orderServiceStatus);
  }

  public getBlocks(orderTypes: any): any {
    return {
      'office': [
        {
          name: 'today_patients',
          title: 'Päevaplaan',
          component: DailyPlanComponent,
          icon: 'pi pi-table',
          showProviderFilter: true,
        },
        {
          name: 'eConsultations',
          title: 'E-konsultatsioonid',
          visitType: orderTypes.e_consultation.serviceSearchFilter.visitType,
          component: EConsultationsTodayComponent,
        },
        {
          name: 'prescriptions',
          title: 'Retseptid',
        },
        {
          name: 'workIncapacityCertificates',
          title: 'Töövõimetuslehed',
        },
        {
          name: 'openMedicalCases',
          title: 'Avatud haigusjuhud/külastused',
        },
      ],
      'radiology': [
        {
          name: 'xray',
          state: 'radiology.xray',
          title: 'Röntgen',
        },
        {
          name: 'mrt',
          state: 'radiology.mrt',
          title: 'MRT'
        },
        {
          name: 'kt',
          state: 'radiology.kt',
          title: 'KT'
        },
        {
          name: 'ultrasound',
          state: 'radiology.ultrasound',
          title: 'Ultraheli'
        }
      ]
    }
  }
}
