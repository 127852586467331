import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IPatient } from "../interfaces/patient.interface";
import { IPatientComment } from "../interfaces/patient-comment.interface";
import { IMedicalCase } from "../interfaces/medical-case.interface";
import { IReferral } from "../interfaces/referral.interface";
import { IPatientInsurance } from "../interfaces/patient-insurance.interface";
import { IOrder } from "../interfaces/order.interface";

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/patients`;
  }

  public get(id: number): Observable<IPatient> {
    return this.http.get<IPatient>(`${this.BACKEND_URL}/${id}`);
  }

  public search(search: string): Observable<IPatient[]> {
    return this.http.get<IPatient[]>(`${this.BACKEND_URL}/search/${search}`);
  }

  public searchWithExternal(search: string): Observable<IPatient[]> {
    return this.http.get<IPatient[]>(`${this.BACKEND_URL}/search/${search}/external`);
  }

  public saveComment(comment: IPatientComment): Observable<IPatientComment> {
    return this.http.post<IPatientComment>(`${this.BACKEND_URL}/comment`, comment);
  }

  public getByMisCode(patientMisCode: number, type: string, params: HttpParams): Observable<IMedicalCase[]> {
    return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/medicalcases/${patientMisCode}/${type}`, {params});
  }

  public getMedicalCase(patientMisId: number, medicalCaseId: number): Observable<IMedicalCase> {
    return this.http.get<IMedicalCase>(`${this.BACKEND_URL}/medicalcases/one/${patientMisId}/${medicalCaseId}`);
  }

  public getCurrentProviderMedicalCases(patientMisId: number, providerId: number, params: any): Observable<IMedicalCase[]> {
    return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/medicalcases/provider/${patientMisId}/${providerId}`, {params});
  }

  public getUnusedPatientReferrals(patientId: number): Observable<IMedicalCase[]> {
    return this.http.get<IMedicalCase[]>(`${this.BACKEND_URL}/patients/referrals/unused/${patientId}`);
  }

  public getUnusedPatientServiceReferrals(patientId: number, serviceId: number): Observable<IReferral> {
    return this.http.get<IReferral>(`${this.BACKEND_URL}/referrals/unused/${patientId}/${serviceId}`);
  }

  public getTehikPatientReferrals(patientId: number): Observable<IReferral[]> {
    return this.http.get<IReferral[]>(`${this.BACKEND_URL}/referrals/tehik/${patientId}`);
  }

  public getFilteredPatientReferrals(params: HttpParams): Observable<IReferral[]> {
    return this.http.get<IReferral[]>(`${this.BACKEND_URL}/referrals`, {params});
  }

  public getServicesForPatientReferrals(patientId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.BACKEND_URL}/referrals/services/${patientId}`);
  }

  public checkInsurance(patientId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/insurance/${patientId}/check`);
  }

  public getInsuranceData(idCode: number): Observable<IPatientInsurance> {
    return this.http.get<IPatientInsurance>(`${this.BACKEND_URL}/insurance/${idCode}/data`);
  }

  public createMedicalCase(patientId: number, personId: number, departmentId: number): Observable<number> {
    return this.http.get<number>(`${this.BACKEND_URL}/medicalcases/create/${patientId}/${personId}/${departmentId}`);
  }

  public hasOpenAppointment(medicalCaseId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/hasOpenAppointment/${medicalCaseId}`);
  }

  public removeComment(commentId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/comment/delete/${commentId}`);
  }

  public saveReferralAnswer(referral: IReferral): Observable<IReferral> {
    return this.http.post<IReferral>(`${this.BACKEND_URL}/saveReferralAnswer`, referral);
  }

  public confirmReferralAnswer(referralId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/confirmReferralAnswer/${referralId}`);
  }

  public getReferralTehikHtml(referralId: number): Observable<string> {
    return this.http.get<string>(`${this.BACKEND_URL}/referral/tehikHtml/${referralId}`);
  }

  public getReferralTehikHtmlWithoutReferralId(oid: string, documentNr: string, idCode: string): Observable<string> {
    return this.http.get<string>(`${this.BACKEND_URL}/referral/tehikHtml/noReferralId/${oid}/${documentNr}/${idCode}`);
  }

  public getPatientPhysiotherapy(patientId: number): Observable<IOrder | null> {
    return this.http.get<IOrder | null>(`${this.BACKEND_URL}/physiotherapy/${patientId}`);
  }

  public getPatientImageBankLink(patientIdCode: string): Observable<string | null> {
    return this.http.get<string | null>(`${this.BACKEND_URL}/imageBank/${patientIdCode}`);
  }
}
