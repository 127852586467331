import { Component, Input } from '@angular/core';
import { MenuItem } from "primeng/api";

@Component({
  selector: 'medis-page-header',
  template: `
      <div class="page__header flex flex-column gap-3 pb-4 border-bottom-1">
        <h1 class="page__title m-0">{{ title }}</h1>
        <medis-breadcrumb [breadcrumbs]="breadcrumbs"></medis-breadcrumb>
      </div>
  `,
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  @Input() breadcrumbs?: MenuItem[];
  @Input() title: string = '';
}
