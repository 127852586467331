 <div style="padding: 0 21px 21px 21px">
  <p-table [value]="patients" class="table patients-table">
  <ng-template pTemplate="header">
    <tr class="w-12">
      <th class="w-2">PATSIENT</th>
      <th class="w-2">TELEFON</th>
      <th class="w-2">OSUTAJA</th>
      <th class="w-2">TIIM</th>
      <th class="w-2">ESMANE KONTAKT</th>
      <th class="w-2">KINDLUSTATUD</th>
      <th></th>
    </tr>
    </ng-template>
    <ng-template pTemplate="body" let-patient>
      <tr>
        <td>
          <div class="flex flex-column gap-1">
            <a class="text-primary patient-link" routerLink="/patient/{{patient.patientId}}">
              {{ convertNameToProperCase(patient.fullName) }}
            </a>
            <span class="text-sm font-semibold line-height-1">{{ patient.idCode }}</span>
          </div>
        </td>
        <td>
          <span>{{ patient.phone }}</span>
        </td>
        <td>
          <span>{{ patient.doctorName }}</span>
        </td>
        <td>
          <span>{{ patient.generalPractitionerTeamName }}</span>
        </td>
        <td>
          <span>{{ patient.gpTeamPrimaryProviderName }}</span>
        </td>
        <td>
          <span>
            <p-tag
              [styleClass]="'white-space-nowrap tag tag--large ' + (getStatusInfo(patient.isInsured).class)"
              rounded="true"
              [value]="getStatusInfo(patient.isInsured).name">
            </p-tag>
          </span>
        </td>
        <td>
          <span class="flex justify-content-end align-items-center">
            <p-button
              severity="secondary"
              [outlined]="true"
              label="Vali"
              (click)="choosePatient(patient)"
              [style]="{ height: '34px', width: '62px'}">
            </p-button>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="7">Midagi pole leitud.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
