import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";

@Component({
  selector: 'medis-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit{
  @Input() sidebarOpen!: boolean;
  @Output() sidebarOpenChange = new EventEmitter<boolean>();

  user: ICurrentUser|null = null;
  departments: any[] = [];
  selectedDepartment: string = '';
  selectedProfession: string = '';
  isMenuActive = false;

  constructor(
    private authService: AuthService,
  ) {
    this.authService.getCurrentUser().subscribe(
      {
        next: value => {this.user = value}
      }
    );
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
    this.sidebarOpenChange.emit(this.sidebarOpen);
  }

  ngOnInit() {
    this.initActions();

    // Set default active department if there are departments available
    if (this.departments.length > 0) {
      this.selectedDepartment = this.departments[0].department;
      this.selectedProfession = this.departments[0].profession;
    }
  }

  private initActions(): void {
    this.departments = [
      {
        department: 'Aktsiaselts Medicum Tervishoiuteenused',
        profession: 'REGISTRATUUR',
      },
      {
        department: 'Eriarstiabi (EA) - Medicum Tervishoiuteenused AS',
        profession: 'PEDIAATRIA',
      }
    ];
  }

  onDepartmentChange(departmentChange: { department: string, profession: string }) {
    this.selectedProfession = departmentChange.profession;
    this.selectedDepartment = departmentChange.department;
    this.isMenuActive = false;
  }

  onMenuToggle(isActive: boolean) {
    this.isMenuActive = !isActive;
  }


}
