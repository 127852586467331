import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {catchError, Observable, of, throwError} from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import {jwtDecode} from "jwt-decode";
import {ToastService} from "../services/toast.service";

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor( private router: Router, private authService: AuthService, private toastService: ToastService ) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
      this.authService.removeAccessToken();
      this.router.navigate(['/auth']);
    }
    return throwError(() => err);
  }

  private validateToken(): void {
    const accessToken = this.authService.getAccessToken();
    if(accessToken !== null){
      let jwtExp = jwtDecode(accessToken).exp;
        if (!jwtExp || (jwtExp < (new Date().getTime() + 1) / 1000)) {
          this.authService.removeAccessToken();
        }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    this.validateToken();

    if (this.authService.hasAccessToken()) {
      const accessToken = this.authService.getAccessToken();
      request = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken
        }
      });
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }
}
