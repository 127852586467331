<div class="modal-body">
  @if (bodyText) {
    <form [formGroup]="reasonForm">
      <div>
        <p>{{ bodyText }}</p>
        <br/>
        @if (showReasonOptions) {
          <select class="form-control single" name="reasonOption" formControlName="reasonOption">
            <option value=""> -- Vali tühistamise põhjus -- </option>
            <option>Ravi saadud</option>
            <option>Patsiendi surm</option>
            <option>Patsient loobus teenusest</option>
            <option>Patsient tasub ise</option>
            <option>Tervisliku seisundi muudatus</option>
            <option>Edasi lükatud patsiendi soovil</option>
            <option>Tervishoiuasutusest tingitud põhjus</option>
            <option>Saatekirja vastus "ei teosta"</option>
            <option>Üle aja läinud makse</option>
          </select>
        }
        @if (showReason) {
          <textarea type="text" name="reason" class="form-control mt-2" formControlName="reason" rows="3"></textarea>
        }
        @if (showReasonError) {
          <div class="alert alert-danger mt-3 mb-0" role="alert">
            Lisa puudumise põhjus!
          </div>
        }
      </div>
    </form>
  }
</div>
<div class="flex justify-content-end gap-2 p-dialog-footer">
  <p-button
    label="Ei"
    icon="pi pi-times"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
  ></p-button>
  <p-button
    label="Jah"
    icon="pi pi-check"
    (click)="ok()"
  ></p-button>
</div>
