// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendUrl: 'https://mispro-api.lorex.ee',
  oldBackendUrl: 'https://mispro-oldapi.lorex.ee',
  externalMedicalCaseUrl: 'https://test-medis:4473/init/',
  referralFormUrl: 'https://test-medis:4453/init/medis',
  referralLaborUrl: 'https://test-medis:8019/init/medis',
  apiUrl: 'http://test.lorexgrupp.ee:955/',
  misUrl: 'http://127.0.0.1:3150',
  sstUrl: 'https://test-medis:8021/api/init/medis',
  title: 'MEDIS - localhost'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
