import { Component, Input, OnInit } from '@angular/core';
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { IProviderPopupInfo } from "@core/interfaces/provider-popup-info.interface";

@Component({
  selector: 'medis-provider-info-popover',
  standalone: true,
  imports: [
    NgbPopover
  ],
  templateUrl: './provider-info-popover.component.html',
})
export class ProviderInfoPopoverComponent implements OnInit{
  @Input() orderService!: IOrderServiceTime;
  provider!: IProviderPopupInfo;

  ngOnInit() {
    if(this.orderService){
      this.provider = {
        phone : this.orderService.providerPhone,
        mobile: this.orderService.providerMobile,
        providerName : this.orderService.providerName
      }
    }
  }
}
