import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'time',
  standalone: true
})
export class TimePipe implements PipeTransform {

  transform(value: string | undefined, format: string = 'HH:mm'): string {
    if (value) {
      return moment(value, format).format(format);
    }
    return '';
  }

}
