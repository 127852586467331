import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMapping } from "@core/interfaces/mapping.interface";
import { ToastService } from "@core/services/toast.service";
import { PersonService } from "@core/services/person.service";
import { IPerson } from "@core/interfaces/person.interface";

@Component({
  selector: 'medis-provider-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        id="types"
        class="w-full"
        styleClass="w-full"
        appendTo="body"
        [options]="providers"
        [filter]="providers.length > 15"
        filterBy="name"
        [ngModel]="selectedProviderId"
        (onChange)="onSelect($event.value)"
        optionValue="personId"
        optionLabel="fullName"
      />
      <label class="text-color-secondary"
             for="types">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProviderSelectListComponent),
      multi: true
    }
  ]
})
export class ProviderSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() labelName: string = '';
  @Output() onProviderChange = new EventEmitter<IMapping>();

  providers: IPerson[] = [];
  selectedProviderId: any;

  constructor(private personService: PersonService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.getTypes();
  }

  private getTypes(): void {
    this.personService.getActiveProvidersForSelect().subscribe({
      next: resolve => {
        this.providers = resolve;
      },
      error: (err: Error) => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.selectedProviderId = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedProviderId = value;
    this.onProviderChange.emit({providerId: value});
    this.onChange(value);
    this.onTouched();
  }
}
