import { Component, Input } from '@angular/core';

@Component({
  selector: 'medis-page-header',
  template: `
      <div class="page__header flex flex-column gap-3 pb-4 border-bottom-1">
        <h1 class="page__title m-0">{{ title }}</h1>
        <medis-breadcrumb></medis-breadcrumb>
      </div>
  `,
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  @Input() title: string = '';
}
