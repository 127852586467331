import { Component } from '@angular/core';

@Component({
  selector: 'medis-e-consultations-today',
  templateUrl: './e-consultations-today.component.html',
  styleUrls: ['./e-consultations-today.component.less']
})
export class EConsultationsTodayComponent {

}
