import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateFormatInterceptor implements HttpInterceptor {
  private dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  private dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body) {
      request = request.clone({
        body: this.convertStringToDates(request.body)});
    }
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertStringToDates(event.body);
        }
      })
    );
  }

  private convertStringToDates(object: any): void {
    if (!object) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertStringToDates(item);
      }
      return;
    }

    if (object instanceof Object) {
      for (const key of Object.keys(object)) {
        const value = object[key];

        if (value instanceof Object && !(value instanceof Date) && !(value instanceof Array)) {
          this.convertStringToDates(value);
        }

        if (value instanceof Array) {
          const dates = [];

          for (const item of value) {
            if (item instanceof Date) {
              dates.push(moment(item).format('YYYY-MM-DD HH:mm:ss'));
            } else if (typeof item === 'string' && (this.dateRegex.test(item) || this.dateTimeRegex.test(item))) {
              dates.push(moment(item, 'YYYY-MM-DD HH:mm:ss').toDate());
            } else {
              this.convertStringToDates(item);
            }
          }

          if (dates?.length > 0) {
            object[key] = dates;
          }
        }

        if (value instanceof Date) {
          object[key] = moment(value).format('YYYY-MM-DD HH:mm:ss');
        } else if (typeof value === 'string' && (this.dateRegex.test(value) || this.dateTimeRegex.test(value))) {
          object[key] = moment(value, 'YYYY-MM-DD HH:mm:ss').toDate();
        }
      }
    }
  }
}
