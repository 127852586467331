import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTimeDisplay',
  standalone: true
})
export class DateTimeDisplayPipe implements PipeTransform {

  transform(date: string | Date | undefined, format: string = 'DD.MM.YYYY HH:mm'): string {
    if (date) {
      const inputDate = moment(date);

      if (inputDate.isValid()) {
        return inputDate.format(format);
      }
      return date.toString();
    }
    return '';
  }

}
