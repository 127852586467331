<header class="header bg-primary flex gap-3">
  <p-button
    size="large"
    styleClass="hamburger border-noround w-4rem h-4rem"
    [icon]="sidebarOpen ? 'pi pi-times' : 'pi pi-bars'"
    (click)="toggleSidebar()"
  />
  <div class="header__wrap flex justify-content-between flex-grow-1 py-2">
    <div class="header__left flex gap-5 align-items-center">
      <p-button class="header__logo" routerLink="/office">MISPro</p-button>
      <div class="header__search">
        <medis-search-bar
          [redirect]="true"
        ></medis-search-bar>
      </div>
    </div>
    <div class="header__right flex gap-5 align-items-center mr-4">
      <p-button
        styleClass="text-white"
        [outlined]="true"
        label="Broneeri aeg"
        icon="pi pi-clock"
        (click)="navigateToReservationPage()"
      />
      <!-- dept-menu component -->
      <app-dept-menu
        [selectedProfession]="selectedProfession"
        [selectedDepartment]="selectedDepartment"
        [departmentProfessionPairs]="departmentProfessionPairs"
        (departmentChanged)="onDepartmentChange($event)"
        (menuToggled)="onMenuToggle($event)"
      ></app-dept-menu>
      <!-- /dept-menu component -->
      <div class="header__profile flex gap-2 align-items-center">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8993 6.61192C14.3274 6.61192 13.0483 7.87385 13.0483 9.425C13.0483 10.6404 13.8512 11.7231 15.0146 12.0988V14.8154C15.0146 16.7119 13.4509 18.255 11.529 18.255C9.60713 18.255 8.04343 16.7119 8.04343 14.8154V10.9138C9.44031 10.7258 10.7269 10.0535 11.6783 9.01038C12.6687 7.92461 13.2143 6.52346 13.2143 5.065V1.63923C13.2143 1.15808 12.8175 0.766538 12.33 0.766538H10.9015C10.8485 0.335385 10.4751 0 10.0238 0C9.53619 0 9.13942 0.391538 9.13942 0.872692V2.40577C9.13942 2.88692 9.53619 3.27846 10.0238 3.27846C10.4751 3.27846 10.8485 2.94308 10.9015 2.51192H11.446V5.065C11.446 7.35808 9.55568 9.22346 7.23196 9.22346C4.90824 9.22346 3.01793 7.35808 3.01793 5.065V2.51192H3.5137C3.5667 2.94308 3.94009 3.27846 4.39143 3.27846C4.87901 3.27846 5.27578 2.88692 5.27578 2.40577V0.872692C5.27578 0.391538 4.87901 0 4.39143 0C3.94009 0 3.5667 0.335385 3.5137 0.766538H2.13435C1.64677 0.766538 1.25 1.15808 1.25 1.63923V5.065C1.25 6.49769 1.77851 7.88 2.73847 8.95731C3.65908 9.99077 4.91058 10.6746 6.2755 10.8923V14.815C6.2755 17.6738 8.63235 19.9996 11.5294 19.9996C14.4264 19.9996 16.7833 17.6738 16.7833 14.815V12.0985C17.9471 11.7231 18.75 10.64 18.75 9.42461C18.75 7.87346 17.4712 6.61154 15.8993 6.61154V6.61192ZM16.9813 9.425C16.9813 10.0138 16.4957 10.4931 15.8989 10.4931C15.3022 10.4931 14.8166 10.0138 14.8166 9.425C14.8166 8.83615 15.3022 8.35692 15.8989 8.35692C16.4957 8.35692 16.9813 8.83615 16.9813 9.425Z"
            fill="white"
          />
        </svg>
        {{ user | userFullName }}
      </div>
    </div>
  </div>
</header>
