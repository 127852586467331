import { Pipe, PipeTransform } from '@angular/core';
import { IPatientContact } from "@core/interfaces/patient-contact.interface";
import { HelperService } from "@core/services/helper.service";

@Pipe({
  name: 'patientContact',
  standalone: true
})
export class PersonAddressPipe implements PipeTransform {
  constructor(private helperService: HelperService) {
  }

  transform(personContact: IPatientContact): string {
    if (personContact) {
      const { city, street, zip, house, flat, county, settlement, longAddress } = personContact;

      const firstParts = [];
      if (county) firstParts.push(county);
      if (longAddress) firstParts.push(longAddress);

      const secondParts = [];
      if (city) secondParts.push(city);

      const thirdParts = [];
      if (settlement) thirdParts.push(settlement);

      const fourthParts = [];
      if (street) fourthParts.push(street);
      if (house) fourthParts.push(house);
      if (flat) fourthParts.push('- '+flat);

      const fifthParts = [];
      if (zip) fifthParts.push(zip);

      const parts = [];
      if(!this.helperService.isEmpty(firstParts)) parts.push(firstParts.join(' '));
      if(!this.helperService.isEmpty(secondParts)) parts.push(secondParts.join(' '));
      if(!this.helperService.isEmpty(thirdParts)) parts.push(thirdParts.join(' '));
      if(!this.helperService.isEmpty(fourthParts)) parts.push(fourthParts.join(' '));
      if(!this.helperService.isEmpty(fifthParts)) parts.push(fifthParts.join(' '));

      return parts.join(', ');
    }
    return '';
  }

}
