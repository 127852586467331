import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class OrderTypesService {
  constructor() {
  }

  getOrderTypes(listCodes: { [code: string]: any }): any {
    return {
      default: {
        name: 'default',
        medicalCaseType: 'default',
        serviceSearchFilter: {},
        serviceGroupSubTypes: [16, 1, 7],
        state: 'office.registrations.orders'
      },
      phone_contact: {
        name: 'phone_contact',
        nameEst: 'Telefonikontakt',
        medicalCaseType: 'default',
        serviceSearchFilter: {
          visitType: 57
        },
        serviceGroupSubTypes: [],
        state: 'office.registrations.phone_contact'
      },
      e_consultation: {
        name: 'e_consultation',
        nameEst: 'E-konsultatsioon',
        medicalCaseType: 'default',
        serviceSearchFilter: {
          visitType: 56
        },
        serviceGroupSubTypes: [],
        state: 'office.registrations.eConsultations'
      },
      home_visit: {
        name: 'home_visit',
        nameEst: 'Koduvisiit',
        medicalCaseType: 'default',
        serviceSearchFilter: {
          visitType: 55
        },
        serviceGroupSubTypes: [],
        state: 'office.registrations.home_visit'
      },
      prescriptions: {
        name: 'prescriptions',
        nameEst: 'Retseptid',
        medicalCaseType: 'default',
        state: 'office.registrations.prescriptions',
        serviceSearchFilter: {
          visitType: null
        },
      },
      workIncapacityCertificates: {
        name: 'workIncapacityCertificates',
        nameEst: 'Töövõimetuslehed',
        medicalCaseType: 'default',
        state: 'office.registrations.workIncapacityCertificates',
        serviceSearchFilter: {
          visitType: null
        },
      },
      openMedicalCases: {
        name: 'openMedicalCases',
        nameEst: 'Avatud haigusjuhud/külastused',
        medicalCaseType: 'default',
        state: 'office.registrations.openMedicalCases',
        serviceSearchFilter: {
          visitType: null
        },
      },
      other: {
        name: 'other',
        nameEst: 'Muu',
        medicalCaseType: 'default',
        serviceSearchFilter: {
          visitType: listCodes['OrderType_Other']
        },
        serviceGroupSubTypes: [],
        state: 'office.registrations.other'
      },
      labor: {
        name: 'labor',
        medicalCaseType: 'lab',
        serviceSearchFilter: {
          studyType: 811,
          serviceBookingNotNeeded: true
        },
        serviceGroupSubTypes: [8],
        state: 'labor.list'
      },
      xray: {
        name: 'radiology',
        medicalCaseType: 'rad',
        serviceSearchFilter: {
          studyType: 7,
          radiologicalStudyType: 8
        },
        serviceGroupSubTypes: [8],
        state: 'radiology.xray'
      },
      mrt: {
        name: 'radiology',
        medicalCaseType: 'rad',
        serviceSearchFilter: {
          studyType: 7,
          radiologicalStudyType: 537
        },
        serviceGroupSubTypes: [8],
        state: 'radiology.mrt'
      },
      kt: {
        name: 'radiology',
        medicalCaseType: 'rad',
        serviceSearchFilter: {
          studyType: 7,
          radiologicalStudyType: 536
        },
        serviceGroupSubTypes: [8],
        state: 'radiology.kt'
      },
      ultrasound: {
        name: 'radiology',
        medicalCaseType: 'rad',
        serviceSearchFilter: {
          studyType: 7,
          radiologicalStudyType: 535
        },
        serviceGroupSubTypes: [8],
        state: 'radiology.ultrasound'
      }
    };
  }
}
