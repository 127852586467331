import { Component, OnInit } from '@angular/core';
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderService } from "@core/services/order.service";
import { IOrderServiceComment } from "@core/interfaces/order-service-comment.interface";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DateTimeDisplayPipe } from "../../pipes/date-time-display.pipe";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { ConfirmModalComponent } from "../confirm/confirm.modal.component";
import { Button } from "primeng/button";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { FloatLabelModule } from "primeng/floatlabel";
import { TableModule } from "primeng/table";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";

@Component({
  selector: 'medis-comment-order-actions-modal',
  standalone: true,
  templateUrl: './comment-order-actions.modal.component.html',
  imports: [
    ReactiveFormsModule,
    DateTimeDisplayPipe,
    Button,
    InputTextareaModule,
    FloatLabelModule,
    TableModule,
  ],
  styleUrl: './comment-order-actions.modal.component.less'
})
export class CommentOrderActionsModalComponent implements OnInit {
  private readonly CONFIRMATION_MODAL_TITLE: string = 'Kommentaari eemaldamine';
  private readonly CONFIRMATION_MODAL_BODY_TEXT: string = 'Kas oled kindel, et soovid kustutada?';

  commentForm!: FormGroup;
  orderServiceTime!: IOrderServiceTime;
  commentLoading = false;
  comments: IOrderServiceComment[] = [];
  currentUser!: ICurrentUser | null;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private orderService: OrderService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private modalService: ModalService,
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    if (this.instance && this.instance.data) {
      this.createForm();
      this.orderServiceTime = this.instance.data['orderServiceTime'];
      if (this.orderServiceTime.orderServiceId) {
        this.getComments();
      }
      if (this.authService.hasAccessToken()) {
        this.currentUser = this.authService.currentUserSubject.value;
      }
    }
  }

  private createForm() {
    this.commentForm = this.formBuilder.group({
      comment: [null],
    })
  }

  closeModal(): void {
    this.instance?.close();
  }

  getComments = () => {
    this.commentLoading = true;
    this.orderService
      .getOrderServiceComments(this.orderServiceTime.orderServiceId)
      .subscribe({
        next: (comments) => {
          this.comments = comments.map(x => ({...x,}));
          this.commentLoading = false;
        },
        error: (err) => {
          console.error(err);
        }
      })
  };

  saveComment = () => {
    const commentText = (this.comment.value || '').trim();
    if (!commentText) {
      return;
    }

    this.commentLoading = true;
    let params = new HttpParams();
    params = params.set('orderServiceId', this.orderServiceTime.orderServiceId)
    params = params.set('comment', this.comment.value)
    params = params.set('userName', this.currentUser ? this.currentUser.firstName : '')
    params = params.set('userIdentifier', this.currentUser ? this.currentUser.email : '')

    this.orderService
      .saveOrderServiceComment(params)
      .subscribe({
        next: () => {
          this.getComments();
          this.commentLoading = false;
          this.comment.setValue('');
        },
        error: (err) => {
          console.error(err);
        }
      })
  };

  deleteComment = (orderServiceCommentId: number) => {
    this.commentLoading = true;

    this.ref = this.modalService.openModal({
      component: ConfirmModalComponent,
      size: ModalSize.SMALL,
      header: this.CONFIRMATION_MODAL_TITLE,
      data: { bodyText: this.CONFIRMATION_MODAL_BODY_TEXT }
    })

    this.ref.onClose.subscribe((confirmed: boolean | string) => {
      if (confirmed) {
        let params = new HttpParams();
        params = params.set('orderServiceCommentId', orderServiceCommentId)

        this.orderService.deleteOrderServiceComment(params)
          .subscribe({
            next: () => {
              this.getComments();
              this.commentLoading = false;
            },
            error: (err) => {
              console.error(err);
            }
          })
      }
    });
  };

  get comment(): FormControl {
    return this.commentForm.get('comment') as FormControl;
  }
}
