@if (authorized){
  <medis-header [(sidebarOpen)]="sidebarOpen"></medis-header>
}

<main class="page flex surface-ground">
  @if (authorized){
    <medis-sidebar [sidebarOpen]="sidebarOpen"></medis-sidebar>
  }
  <div class="page__content w-full flex flex-column">
    <router-outlet>
    </router-outlet>
  </div>
</main>
<p-toast position="bottom-right"></p-toast>
