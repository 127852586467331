import { Component, OnInit } from '@angular/core';
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { DateTimeDisplayPipe } from "../../pipes/date-time-display.pipe";
import {
  FinancialSourceLabelComponent
} from "../../components/financial-source-label/financial-source-label.component";
import { TimePipe } from "../../pipes/time.pipe";
import { PersonAddressPipe } from "../../pipes/person-address.pipe";
import {
  OrderServiceInvoiceStatusComponent
} from "../../components/order-service-invoice-status/order-service-invoice-status.component";
import { CheckboxModule } from "primeng/checkbox";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { TableModule } from "primeng/table";
import {IPatient} from "@core/interfaces/patient.interface";
import {IOrderService} from "@core/interfaces/order-service.interface.interface";
import {IReferral} from "@core/interfaces/referral.interface";
import {IServiceFinancialSource} from "@core/interfaces/service-financial-source.interface";
import {IOrderServicePayment} from "@core/interfaces/order-service-payment.interface";
import {IOrderServiceVisitPriceInfo} from "@core/interfaces/order-service-visit-price-info.interface";
import {PatientService} from "@core/services/patient.service";
import {OrderService} from "@core/services/order.service";
import {OrderServiceService} from "@core/services/order-service.service";
import {InvoiceService} from "@core/services/invoice.service";
import {FinSourceName} from "@core/enums/financial-source.enum";
import {InvoiceStatus} from "@core/enums/invoice-status.enum";
import {VisitPriceStatus} from "@core/enums/visit-price-status.enum";
import {OrderReferralStatus} from "@core/enums/order-referral-status.enum";
import {FinancialSource} from "@core/enums/financial-sources.enum";

@Component({
  selector: 'medis-present-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    DateTimeDisplayPipe,
    FinancialSourceLabelComponent,
    TimePipe,
    PersonAddressPipe,
    OrderServiceInvoiceStatusComponent,
    CheckboxModule,
    ReactiveFormsModule,
    DropdownModule,
    ToastModule,
    TableModule,
  ],
  providers: [MessageService],
  templateUrl: './present.modal.component.html',
  styleUrl: './present.modal.component.less'
})
export class PresentModalComponent implements OnInit {
  reasonForm!: FormGroup;
  paymentInfoForm!: FormGroup;
  patientId!: number;
  orderServiceTime!: any;
  patient!: IPatient;
  patientError: boolean = false;
  patientDataInvalid: boolean = false;
  service: IOrderService | undefined;
  selectedReferral: IReferral | undefined;
  financialSourceCode: string | undefined;
  serviceFinancialSources: IServiceFinancialSource[] = [];
  showETervisReferrals: boolean = false;
  showReferrals: boolean = false;
  unpaidService!: IOrderServicePayment;
  noPaymentProcessComplete: boolean = true;
  visitPriceInfo!: IOrderServiceVisitPriceInfo;
  noPayment: boolean = false;
  creatingInvoice: boolean = false;
  visitPriceInfoChanged: boolean = false;
  saveLoading: boolean = false;
  exemptionReasons = [
    {label: 'Medicumi töötaja', value: 'MedicumEmployee'},
    {label: 'Medicumi eriarsti poolt suunatud', value: 'MedicumDoctorReferral'},
    {label: 'Sama eriala arsti saatekirjaga', value: 'SameDoctorSpecialty'},
    {label: 'Kuni 2-aastane isik', value: 'Under2'},
    {label: 'Kuni 18-aastane isik', value: 'Under18'},
    {label: 'Vältimatu abi', value: 'Cito'},
    {label: 'Rasedus', value: 'Pregnancy'},
  ];

  instance: DynamicDialogComponent | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private patientService: PatientService,
    private orderService: OrderService,
    private orderServiceService: OrderServiceService,
    private invoiceService: InvoiceService,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    if (this.instance && this.instance.data) {
      this.patientId = this.instance.data['patientId'];
      if (this.patientId) {
        this.getPatient();
      }
      this.orderServiceTime = this.instance.data['orderServiceTime'];
      if (this.orderServiceTime) {
        this.getOrderService();
        this.getUnpaidOrderService();
      }
    }
  }

  private createReasonFormGroup(): void {
    this.reasonForm = this.formBuilder.group({
      exemptionReason: [this.visitPriceInfo.exemptionReason ?? null],
      noPayment: [true],
    })
  }

  private createPaymentFormGroup(): void {
    this.paymentInfoForm = this.formBuilder.group({
      financialSource: [this.service?.financialSourceId ?? null],
    })
  }

  private getPatient(): void {
    this.patientService.get(this.patientId).subscribe({
      next: (result) => {
        this.patient = result;
        this.patientDataInvalid = this.isPatientDataInvalid(result);
      },
      error: (err) => {
        this.patientError = true;
        this.messageService.add({ severity: 'danger', summary: 'Error', detail: err, key: 'br', sticky: true });
      }
    })
  }

  private getOrderService(onPayerChange = false) {
    this.orderService.get(this.orderServiceTime.orderId).subscribe({
      next: (result) => {
        if (onPayerChange && this.orderServiceTime.orderServiceTimeId) {
          this.orderServiceTime = result.orderServices
            .find(os => os.orderServiceId === this.orderServiceTime.orderServiceId)?.orderServiceTimes
            .find(ost => ost.orderServiceTimeId === this.orderServiceTime.orderServiceTimeId) ?? this.orderServiceTime;
        }
        if (onPayerChange && this.orderServiceTime.orderServiceQueueId) {
          this.orderServiceTime = result.orderServices
            .find(os => os.orderServiceId === this.orderServiceTime.orderServiceId)?.orderServiceQueues
            .find(ost => ost.orderServiceQueueId === this.orderServiceTime.orderServiceQueueId) ?? this.orderServiceTime;
        }
        // this.medicalCaseId = result.medicalCaseId;
        this.service = result.orderServices.find(os => os.orderServiceId === this.orderServiceTime.orderServiceId);
        this.createPaymentFormGroup()
        // this.service.orderServiceTimes = this.service.orderServiceTimes.filter(ost => ost.orderServiceTimeStatus === conf.api.orderServiceStatuses.confirmed);
        //
        // this.provider = result.provider;
        this.financialSourceCode = this.service?.financialSourceCode;
        // this.service.payerName = this.orderServiceTime.patientName;
        this.selectedReferral = this.service?.referral;
        if (!this.selectedReferral &&
          this.service?.serviceFinancialSources?.find(s => s.financialSourceId === this.service?.financialSourceId)?.isReferralRequired
        ) {
          this.showETervisReferrals = true;
        }

        if (this.service) {
          this.service.serviceFinancialSources.forEach(source => {
            if (source.financialSourceCode !== FinSourceName.PayerHealthInsuranceFund
              || this.financialSourceCode === FinSourceName.PayerHealthInsuranceFund) {
              this.serviceFinancialSources?.push(source);
            }
          })
        }
      }
    })
  }

  private getUnpaidOrderService() {
    this.orderServiceService.getUnpaidOrderService(this.orderServiceTime.orderServiceId).subscribe({
      next: (result) => {
        this.unpaidService = result;
        this.noPaymentProcessComplete = true;
        this.getVisitPriceInfo();
      }
    })
  }

  private getVisitPriceInfo(): void {
    this.orderServiceService.getVisitPriceInfo(this.orderServiceTime.orderServiceId).subscribe({
      next: (result) => {
        this.visitPriceInfo = result;
        this.noPayment = result.status === VisitPriceStatus.EXEMPTED;
        this.noPaymentProcessComplete = false;
        this.createReasonFormGroup();
      }
    })
  }

  private isPatientDataInvalid(patient: IPatient): boolean {
    const requiredFields = ['firstname', 'lastname', 'idCode', 'gender', 'birthday', 'country'];
    return requiredFields.some(field => !patient[field as keyof typeof patient]);
  }

  openReferral() {

  }

  createInvoiceAndPay() {
    this.creatingInvoice = true;
    if (this.unpaidService?.invoice.invoiceNumber) {
      this.payInvoice(this.unpaidService?.invoice.invoiceNumber);
    } else {
      this.invoiceService.createPresentInvoice(this.unpaidService?.orderServiceId).subscribe({
        next: (result) => {
          if (result.invoiceNumber) {
            this.getUnpaidOrderService();
            this.payInvoice(result.invoiceNumber);
          } else {
            this.creatingInvoice = false;
          }
        },
        error: (err) => {
          this.creatingInvoice = false;
          this.messageService.add({ severity: 'danger', summary: 'Error', detail: err, key: 'br', sticky: true });
        }
      })
    }
  }

  payInvoice(invoiceNumber: string) {
    this.invoiceService.get(invoiceNumber).subscribe({
      next: (result) => {
        window.open(result.link, '_blank');
        this.openDirectoModal(result.link);
        this.creatingInvoice = false;
      },
      error: (err) => {
        this.messageService.add({ severity: 'danger', summary: 'Error', detail: err, key: 'br', sticky: true });
      }
    })
  }

  openDirectoModal(link: string): void {

  }

  toggleMustPay() {
    this.noPayment = !this.noPayment;
    this.visitPriceInfoChanged = true;
  }

  saveVisitPriceInfo() {
    this.visitPriceInfo.status = this.noPayment ? VisitPriceStatus.EXEMPTED : VisitPriceStatus.REQUIRED;
    this.visitPriceInfo.amount = 0;
    this.orderServiceService.saveVisitPriceInfo(this.visitPriceInfo).subscribe({
      next: () => {
        this.getUnpaidOrderService();
        this.noPaymentProcessComplete = true;
        this.visitPriceInfoChanged = false;
      },
      error: (err) => {
        this.messageService.add({ severity: 'danger', summary: 'Error', detail: err, key: 'br', sticky: true });
      }
    })
  }

  onFinSourceChange = () => {
    const serviceFinancialSource = this.service?.serviceFinancialSources?.find(s => s.financialSourceId === this.service?.financialSourceId)
    this.showETervisReferrals = !!serviceFinancialSource?.isReferralRequired;
    this.financialSourceCode = serviceFinancialSource?.financialSourceCode;
  };

  confirmPayer() {
    if (this.service) {
      this.orderService.updateOrderService(this.service).subscribe({
        next: () => {
          this.serviceFinancialSources = [];
          this.getOrderService(true);
          this.getUnpaidOrderService();
        },
        error: (err) => {
          this.messageService.add({ severity: 'danger', summary: 'Error', detail: err, key: 'br', sticky: true });
        }
      })
    }
  }

  cancel(serviceUpdated = false): void {
    this.ref.close(serviceUpdated);
  }

  presentButtonDisabled() {
    return (
      !this.noPaymentProcessComplete
      && this.visitPriceInfo.status === VisitPriceStatus.UNDEFINED)
      || (this.unpaidService?.invoice
        && !(this.unpaidService?.invoice.invoiceStatus === InvoiceStatus.COMPLETED
          ||  this.unpaidService?.invoice.invoiceStatus === InvoiceStatus.PREPAID));
  }

  submit() {
    if (this.paymentInfoForm.invalid || this.patientDataInvalid) {
      return;
    }

    this.saveLoading = true;

    if (this.selectedReferral) {
      this.saveReferral(this.selectedReferral);
    }

    if (this.service) {
      this.orderService.updateOrderService(this.service).subscribe({
        next: () => {
          this.cancel(true);
        },
        error: (err) => {
          this.messageService.add({ severity: 'danger', summary: 'Error', detail: err, key: 'br', sticky: true });
        },
        complete: () => {
          this.saveLoading = false;
        }
      })
    }
  }

  saveReferral(referral: IReferral): void {
    if (this.service) {
      if (referral.referralId) {
        this.service.referralId = referral.referralId;
      } else {
        this.service.eTervisReferral = referral;
      }
      this.service.referralStatus = OrderReferralStatus.CONFIRMED;
    }
  }

  protected readonly FinancialSource = FinancialSource;
  protected readonly InvoiceStatus = InvoiceStatus;
  protected readonly VisitPriceStatus = VisitPriceStatus;
  protected readonly FinSourceName = FinSourceName;
}
