@if (filterForm) {
  <section class="dashboard px-4 flex flex-column gap-4">
    <div class="dashboard-filter flex flex-column gap-3">
      <form [formGroup]="filterForm">
        <div class="dashboard-topbar__top row flex gap-4 pt-5">
          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="provider"
                class="w-full"
                styleClass="w-full"
                [options]="providers"
                formControlName="provider"
                optionValue="personId"
                optionLabel="fullname"
                [filter]="true"
                (onChange)="onProviderChange(); addFiltersToURL()"/>
              <label class="text-color-secondary" for="provider">Osutaja</label>
            </p-floatLabel>
          </div>

          <div class="flex flex-row align-items-center gap-1">
            <p-button (onClick)="datePickerSetPreviousDay()" icon="pi pi-chevron-left" [outlined]="true" severity="secondary"/>
            <p-floatLabel>
              <p-calendar
                id="bookingDate"
                inputId="bookingDate"
                formControlName="bookingDate"
                [firstDayOfWeek]="1"
                dateFormat="dd.mm.yy"
                [iconDisplay]="'input'"
                [showIcon]="true"
                class="w-14rem"
                styleClass="w-14rem"
                (onSelect)="onDateChange(); addFiltersToURL()"/>
              <label class="text-color-secondary" for="bookingDate">Kuupäev</label>
            </p-floatLabel>
            <p-button (onClick)="datePickerSetNextDay()" icon="pi pi-chevron-right" [outlined]="true" severity="secondary"/>
          </div>

          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="service"
                class="w-full"
                styleClass="w-full"
                [filter]="true"
                [options]="services"
                [showClear]="true"
                formControlName="service"
                optionValue="id"
                optionLabel="value"
                placeholder="Valige teenus" (onChange)="onServiceChange(); addFiltersToURL()"/>
              <label class="text-color-secondary" for="service">Teenus</label>
            </p-floatLabel>
          </div>

          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="location"
                [options]="locations"
                formControlName="location"
                placeholder="Valige asukoht"
                class="w-full"
                styleClass="w-full"
                optionValue="id"
                optionLabel="value"
                [filter]="true"
                [showClear]="true"
                (onChange)="onLocationChange();addFiltersToURL()"/>
              <label class="text-color-secondary" for="location">Asukoht</label>
            </p-floatLabel>
          </div>
        </div>
      </form>
      <div class="dashboard-filter__bottom row flex gap-4 justify-content-between">
        <div class="dashboard-filter__left">
          @if (tableViewForm) {
            <form [formGroup]="tableViewForm">
              <p-selectButton
                [options]="tableViews"
                [multiple]="true"
                formControlName="tableView"
                optionValue="value"
                (onChange)="filterBookings();addFiltersToURL()"
              >
                <ng-template let-option pTemplate="item">
                  {{ getDynamicLabel(option) }}
                </ng-template>
              </p-selectButton>
            </form>
          }
        </div>
        <div class="dashboard-filter__right">
          <p-button [disabled]="!filteredTimes.length || filteredTimes.length === 0" icon="pi pi-print" [outlined]="true" (onClick)="printAllPatientsByDate()"/>
        </div>
      </div>
    </div>

    <medis-patients-table [data]="filteredTimes"
                          [bookingDate]="bookingDate.value"
                          (updateList)="updateList($event)"></medis-patients-table>
  </section>
}
