import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DashboardService } from "@core/services/dashboard.service";
import { IPerson } from "@core/interfaces/person.interface";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { AuthService } from "@core/services/auth.service";
import { MisService } from "@core/services/mis.service";

import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IOrderServicesData } from "@core/interfaces/order-services-data.interface";
import { IOrderServiceQueue } from "@core/interfaces/order-service-queue.interface";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { Subscription } from "rxjs";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PrintPatientsModalComponent } from "@shared/modals/print-patients/print-patients.modal.component";
import { DashboardTableView } from '@core/enums/dashboard-table-view.enum';
import { IBookingTime } from "@core/interfaces/booking-time.interface";
import { ModalService } from "@core/services/modal.service";
import { ModalSize } from "@core/enums/modalSize";
import { DatePipe, Location } from "@angular/common";
import { IIdValue } from "@core/interfaces/id-value";
import { AvailableBookingStateEnum } from "@core/enums/available-booking-state-enum";

@Component({
  selector: 'medis-daily-plan',
  templateUrl: './daily-plan.component.html',
})

export class DailyPlanComponent implements OnInit, OnChanges {
  @Input() showProviderFilter!: boolean;

  filterForm!: FormGroup;
  tableViewForm!: FormGroup;
  providerId!: number | null;
  providers: IPerson[] = [];
  currentUser!: ICurrentUser | null;
  loading = false;
  onlyActive = true;
  orderServicesData :{orderServicesWaiting: any[], orderServiceTimes: any[], orderServicesPresent: any[]} = {
    orderServicesWaiting: [],
    orderServiceTimes: [],
    orderServicesPresent: [],
  };
  filteredTimes: IOrderServiceTime[] = [];
  cancelledTimesCount: number = 0;
  presentTimesCount: number = 0;
  osFilter: any = {};
  services: IIdValue[] = [];
  locations:IIdValue[] = [];
  selectedProvider!: number;
  personId: number = 0;
  values: any;
  tableViews = [
    { label: `Kohale jõudnud patsiendid (${this.presentTimesCount})`, value: DashboardTableView.ARRIVED },
    { label: `Tühistatud (${this.cancelledTimesCount})`, value: DashboardTableView.CANCELLED },
    { label: 'Vabad ajad', value: DashboardTableView.FREE },
  ];

  currentUserSubscription!: Subscription;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private misService: MisService,
    private router: Router,
    private route: ActivatedRoute,
    private currentLocation: Location,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private datePipe: DatePipe,
    ) {
  }

  ngOnInit() {
    this.createFilterFormGroup();
    this.createTableViewFormGroup();

    this.currentUserSubscription = this.authService.currentUserSubject.subscribe(currentUser => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.providerId = currentUser.personId;
        this.loadProvidersAndOrderServices();
      } else {
        this.authService.setCurrentUserToSubject();
        this.currentUser = this.authService.currentUserSubject.value;
        this.loadProvidersAndOrderServices();
      }
    });
  }

  onServiceChange() {
    this.filterOrderServiceTimes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showProviderFilter'].currentValue !== changes['showProviderFilter'].previousValue) {
      this.filteredTimes = [];
      this.cancelledTimesCount = 0;
      this.presentTimesCount = 0;
      this.createFilterFormGroup();
      this.createTableViewFormGroup();
      this.loadProvidersAndOrderServices();
    }
  }

  private createFilterFormGroup(): void {
    this.filterForm = this.formBuilder.group({
      provider: [null],
      bookingDate: [new Date()],
      service: [null],
      location: [null],
    })
  }

  private createTableViewFormGroup(): void {
    this.tableViewForm = this.formBuilder.group({
      tableView: [[DashboardTableView.ARRIVED]],
    })
  }

  filterBookings(): void {
    if (this.tableView.value.includes(DashboardTableView.FREE)) {
      this.loadOrderServices();
    }
    this.filterOrderServiceTimes();
  }

  loadProvidersAndOrderServices() {
    return this.dashboardService.getDashboardProviders()
      .subscribe({
      next: (response) => {
        this.providers = response;

        let provider = this.route.snapshot.queryParamMap.get('provider') ?? this.currentUser?.personId;
        let date = this.route.snapshot.queryParamMap.get('date');
        let service = this.route.snapshot.queryParamMap.get('service');
        let location = this.route.snapshot.queryParamMap.get('location');
        let tableView = this.route.snapshot.queryParamMap.getAll('tableView');

        if(this.providers.some(x => x.personId == provider)){
          this.provider.setValue(Number(provider));
        }

        if(date){
          this.bookingDate.setValue(new Date(Number(date)));
        }

        if(!tableView.some(x => x === DashboardTableView.ARRIVED)
          && tableView.length === 0
          && !service
          && !location
          && !date
          && !this.route.snapshot.queryParamMap.get('provider')) {
          tableView.push(DashboardTableView.ARRIVED);
        }
        this.tableView.setValue(tableView);

        this.loadOrderServices();

        if(service){
          this.service.setValue(Number(service));
        }
        if(location){
          this.location.setValue(Number(location));
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  loadOrderServices(showLoading = true) {
    const personId = this.provider.value ?? this.currentUser?.personId;
    this.location.setValue(null);
    this.service.setValue(null);

    if (personId) {
      this.loading = showLoading;
      const date = this.datePipe.transform(this.bookingDate.value, 'YYYY-MM-dd');
      this.dashboardService.getOrderServicesByDate(date, personId, {
        getAvailableBookings: this.tableView.value.includes(DashboardTableView.FREE),
        ignoreBlockUi: true
      }).subscribe({
        next: (response) => {
          this.parseOrderServices(response);
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.loading = false;
          this.populateServices();
          this.populateLocations();
        }
      })
    } else {
      this.orderServicesData = {
        orderServicesWaiting: [],
        orderServiceTimes: [],
        orderServicesPresent: [],
      };

      this.populateServices();
      this.populateLocations();
    }
  }

  datePickerSetNextDay = () => {
    const nextDay = new Date(this.bookingDate.value);
    nextDay.setDate(this.bookingDate.value.getDate() + 1);
    this.bookingDate.setValue(nextDay);
    this.onDateChange();
    this.addFiltersToURL();

  }

  datePickerSetPreviousDay = () => {
    const previousDay = new Date(this.bookingDate.value);
    previousDay.setDate(this.bookingDate.value.getDate() - 1);
    this.bookingDate.setValue(previousDay);
    this.onDateChange();
    this.addFiltersToURL();
  }

  printAllPatientsByDate(){
    const providerName = this.filteredTimes[0] ? this.filteredTimes[0].providerName : null;
    const providerDoctorCode = this.filteredTimes[0] ? this.filteredTimes[0].providerDoctorCode : null;

    this.modalService.openModal({
      component: PrintPatientsModalComponent,
      size: ModalSize.LARGE,
      header: `${providerName} ${providerDoctorCode} (${this.datePipe.transform(this.bookingDate.value, 'dd.MM.YYYY')})`,
      data: this.getPrintData(this.filteredTimes)
    })
  };

  private getPrintData(printData: any[]): any {
    return {
      printData,
      osFilter: this.osFilter,
      isOrderServiceTimesData: true,
      onlyActive: this.onlyActive
    }
  }

  refresh(showLoading = true) {
    this.loadOrderServices(showLoading);
  }

  onDateChange() {
    this.resetServiceAndLocationFilters();
    this.loadOrderServices();
  }

  openPatientInMis(patientMisCode: number | null) {
    if (patientMisCode) {
      this.misService.openPatientInMis(patientMisCode);
    }
  }


  onProviderChange = () => {
    this.resetServiceAndLocationFilters();
    this.refresh();
  };

  openOrder(orderId: number) {
    this.router.navigate(['orders/edit', orderId])
  }

  createOrder(orderService: IOrderServiceTime) {
    if (orderService.nextOrderId) {
      this.openOrder(orderService.nextOrderId);
    }
    else{
      this.router.navigate(['orders/create'], {
        queryParams: {
          patientId: orderService.patientId,
          personId: orderService.providerId,
          medicalCaseId: orderService.medicalCaseId,
          misAppointmentId: orderService.misAppointmentId,
          parentOrderServiceId: orderService.orderServiceId,
          externalMedicalCaseId: orderService.externalMedicalCaseId,
          externalAppointmentId: orderService.externalAppointmentId
        }
      });
    }
  }

  parseOrderServices = (response: IOrderServicesData) => {
    const { orderServiceQueues, orderServiceTimes, availableBookings } = response;
    const presentOrderServices = orderServiceQueues.filter(osq => osq.orderServiceStatus === OrderServiceStatus.PRESENT) || [];
    const presentOrderServiceTimes = orderServiceTimes.filter(osq => osq.orderServiceTimeStatus === OrderServiceStatus.PRESENT) || [];
    const orderServicesPresent = [...presentOrderServices, ...presentOrderServiceTimes];
    const orderServicesProviderWaitingList = orderServiceQueues.filter(x => x.providerId);

    this.orderServicesData = {
      orderServiceTimes: this.mergeAvailableBookingsAndOrderServiceTimes(orderServiceTimes, orderServicesProviderWaitingList, availableBookings),
      orderServicesWaiting: [],
      orderServicesPresent,
    };

    this.filterOrderServiceTimes();

    return response;
  };

  private filterOrderServiceTimes(): void {
    this.osFilter.serviceId = this.service?.value ?? null;
    if (this.location?.value) {
      this.osFilter.roomId = this.location?.value;
    } else {
      delete(this.osFilter.roomId);
    }

    this.filteredTimes = this.filterArrayByCriteria(this.orderServicesData.orderServiceTimes, this.osFilter);
    this.filteredTimes = this.filterTimesByStatuses(this.filteredTimes);
    this.filteredTimes.sort((a,b) => (a.timeFrom > b.timeFrom) ? 1 : ((b.timeFrom > a.timeFrom) ? -1 : 0))
    this.filteredTimes.sort((a,b) => {
      if (a.orderServiceStatus === OrderServiceStatus.FINISHED && b.orderServiceStatus !== OrderServiceStatus.FINISHED) {
        return 1;
      } else if (a.orderServiceStatus !== OrderServiceStatus.FINISHED && b.orderServiceStatus === OrderServiceStatus.FINISHED) {
        return -1;
      } else {
        return 0;
      }
    })

    this.presentTimesCount = this.orderServicesData.orderServiceTimes
      .filter(time => (time.orderServiceTimeStatus ?? time.orderServiceStatus) === OrderServiceStatus.PRESENT).length;
    this.cancelledTimesCount = this.orderServicesData.orderServiceTimes
      .filter(time => (time.orderServiceTimeStatus ?? time.orderServiceStatus) === OrderServiceStatus.CANCELLED).length;
  }

  filterArrayByCriteria<T>(array: T[], criteria: Partial<T>): T[] {
    return array.filter(item => {
      for (let key in criteria) {
        if (criteria[key] !== null && item[key] !== criteria[key]) {
          return false;
        }
      }
      return true;
    });
  }

  private filterTimesByStatuses(times: IOrderServiceTime[]): IOrderServiceTime[] {
    times = times.filter(time => {
      let status = time.orderServiceTimeStatus ?? time.orderServiceStatus ?? time.state;

      return ![OrderServiceStatus.CANCELLED, OrderServiceStatus.PRESENT, AvailableBookingStateEnum.FREE].includes(status) ||
        this.tableView.value.includes(
          this.mapOrderServiceStatusToDashboard(status)
        );
    });
    return times;
  }

  private mapOrderServiceStatusToDashboard(orderServiceStatus: any): DashboardTableView|null {
    switch (orderServiceStatus){
      case OrderServiceStatus.CANCELLED:
        return DashboardTableView.CANCELLED;
      case OrderServiceStatus.PRESENT:
        return DashboardTableView.ARRIVED;
      case AvailableBookingStateEnum.FREE:
        return DashboardTableView.FREE;
    }
    return null;
  }

  getDynamicLabel(option: any): string {
    if (option.value === DashboardTableView.ARRIVED) {
      return `Kohale jõudnud patsiendid (${this.presentTimesCount})`;
    }
    if (option.value === DashboardTableView.CANCELLED) {
      return `Tühistatud (${this.cancelledTimesCount})`;
    }
    return option.label;
  }

  mergeAvailableBookingsAndOrderServiceTimes = (orderServiceTimes: IOrderServiceTime[], waitingList: IOrderServiceQueue[], availableBookings: IBookingTime[]) => {
    let mergedData = [...orderServiceTimes || [], ...availableBookings || [], ...waitingList || []];
    mergedData.sort((a, b) => {
      const timeA = a.timeFrom != null ? new Date(a.timeFrom).getTime() : 0;
      const timeB = b.timeFrom != null ? new Date(b.timeFrom).getTime() : 0;

      if (a.orderServiceTimeStatus === OrderServiceStatus.FINISHED && b.orderServiceTimeStatus !== OrderServiceStatus.FINISHED) {
        return -1;
      } else if (a.orderServiceTimeStatus !== OrderServiceStatus.FINISHED && b.orderServiceTimeStatus === OrderServiceStatus.FINISHED) {
        return 1;
      } else {
        return timeA - timeB;
      }
    });

    return mergedData;
  }

  populateLocations() {
    const combinedArray = [...this.orderServicesData.orderServiceTimes, ...this.orderServicesData.orderServicesPresent];
    this.locations = [...combinedArray].map(uniqueKey => {
      return {id: uniqueKey.roomId, value: `${uniqueKey.departmentShort} ${uniqueKey.roomCode}`};
    }).filter((value, index, array) => {
      return array.findIndex(val => value.id === val.id) === index;
    });
  }

  populateServices() {
    this.services = [...this.orderServicesData.orderServiceTimes, ...this.orderServicesData.orderServicesPresent]
      .map(item => {return {id: item.serviceId, value: item.serviceName}})
      .filter((value, index, array) => {
        return array.findIndex(val => value.id === val.id) === index;
      });
  }

  onLocationChange() {
    this.filterOrderServiceTimes();
  }

  resetServiceAndLocationFilters() {
    this.filterForm.patchValue({
      service: null,
      location: null,
    });
    this.services = [];
    this.locations = [];
  }

  addFiltersToURL(){
    let date = new Date(this.bookingDate.value).getTime();
    let url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {
        provider: this.provider.value,
        date: date,
        location: this.location.value,
        service: this.service.value,
        tableView: this.tableView.value
      }}).toString()

    this.currentLocation.go(url)
  }

  updateList(timeData: {time: IOrderServiceTime, status: OrderServiceStatus}): void {
    timeData.time.orderServiceTimeStatus = timeData.time.orderServiceStatus = timeData.status;
    this.filterOrderServiceTimes();
  }

  get tableView(): FormControl {
    return this.tableViewForm.get('tableView') as FormControl;
  }

  get provider(): FormControl {
    return this.filterForm.get('provider') as FormControl;
  }

  get bookingDate(): FormControl {
    return this.filterForm.get('bookingDate') as FormControl;
  }

  get service(): FormControl {
    return this.filterForm.get('service') as FormControl;
  }

  get location(): FormControl {
    return this.filterForm.get('location') as FormControl;
  }
}
