import { Component, Input, OnInit } from '@angular/core';
import { FinancialSource } from "@core/enums/financial-sources.enum";
import { NgClass } from "@angular/common";
import { BadgeModule } from "primeng/badge";
import { TagModule } from "primeng/tag";
import { TooltipModule } from "primeng/tooltip";
import { IMapping } from "@core/interfaces/mapping.interface";
import { BadgeType } from "@core/enums/badge-type.enum";

@Component({
  selector: 'medis-financial-source-label',
  standalone: true,
  imports: [
    NgClass,
    BadgeModule,
    TagModule,
    TooltipModule,
  ],
  template: `
    <p-tag
      rounded="true"
      [value]="source.short"
      [styleClass]="source.style"
      [pTooltip]="source.full"
      tooltipPosition="top"
    ></p-tag>
  `
})

export class FinancialSourceLabelComponent implements OnInit {
  @Input() financialSource!: FinancialSource;
  @Input() short: boolean = true;
  source: any;

  private readonly sourceStyleMap: IMapping = {
    [BadgeType.INFO]: 'tag tag--blue',
    [BadgeType.WARNING]: 'tag tag--orange',
    [BadgeType.YELLOW]: 'tag tag--yellow',
    [BadgeType.PURPLE]: 'tag tag--purple',
    [BadgeType.AZURE]: 'tag tag--azure',
  };

  private readonly labels = {
    [FinancialSource.PERSON]: {short: 'PT', full: 'Patient', style: this.sourceStyleMap[BadgeType.PURPLE]},
    [FinancialSource.INSURANCE]: {short: 'TK', full: 'Tervisekassa', style: this.sourceStyleMap[BadgeType.YELLOW]},
    [FinancialSource.UNEMPLOYMENT_INSURANCE]: {
      short: 'TK',
      full: 'Töötukassa',
      style: this.sourceStyleMap[BadgeType.WARNING]
    },
    [FinancialSource.SOCIAL_INSURANCE]: {
      short: 'SKA',
      full: 'Sotsiaalkindlustusamet',
      style: this.sourceStyleMap[BadgeType.INFO]
    },
    [FinancialSource.ANOTHER_JURIDICAL_PERSON]: {
      short: 'TJI',
      full: 'Teine juriidiline isik',
      style: this.sourceStyleMap[BadgeType.AZURE]
    },
  };

  ngOnInit() {
    this.source = this.labels[this.financialSource]
      || {short: this.financialSource, full: this.financialSource, style: this.sourceStyleMap[BadgeType.INFO]};
  }
}
