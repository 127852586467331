import { Component, OnInit } from '@angular/core';
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { BookingTimeState } from "@core/enums/booking-time-state.enum";
import { PrintService } from "@core/services/print.service";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'medis-print-patients',
  templateUrl: './print-patients.modal.component.html',
  styleUrl: './print-patients.modal.component.less'
})
export class PrintPatientsModalComponent implements OnInit {
  printData: any[] = [];
  osFilter: any = {};
  isOrderServiceTimesData: boolean = false;
  onlyActive: boolean = false;

  protected readonly OrderServiceStatus = OrderServiceStatus;
  protected readonly BookingTimeState = BookingTimeState;

  instance: DynamicDialogComponent | undefined;

  constructor(private printService: PrintService, private ref: DynamicDialogRef, private dialogService: DialogService) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit() {
    if (this.instance && this.instance.data) {
      this.printData = this.instance.data['printData'];
      this.osFilter = this.instance.data['osFilter'];
      this.isOrderServiceTimesData = this.instance.data['isOrderServiceTimesData'];
      this.onlyActive = this.instance.data['onlyActive'];
    }
  }

  print = () => {
    this.printService.printDiv('printSection');
  }

  closeModal(): void {
    this.ref.close();
  }
}
