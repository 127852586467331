import { Component, OnInit } from '@angular/core';
import { NgForOf } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { Button } from "primeng/button";

@Component({
  selector: 'medis-confirm-modal',
  standalone: true,
  imports: [
    NgForOf,
    ReactiveFormsModule,
    Button
  ],
  templateUrl: './confirm.modal.component.html',
  styleUrl: './confirm.modal.component.less'
})
export class ConfirmModalComponent implements OnInit {
  bodyText: string = '';
  showReasonOptions: boolean = false;
  showReason: boolean = false;
  showReasonError: boolean = false;
  immutableData: any = null;

  reasonForm!: FormGroup;

  instance: DynamicDialogComponent | undefined;

  constructor(private formBuilder: FormBuilder,
              private dialogService: DialogService,
              private ref: DynamicDialogRef,
              ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      this.bodyText = this.instance.data['bodyText'];
    }
    this.reasonForm = this.formBuilder.group({
      reasonOption: [null],
      reason: [null],
    })
  }

  ok() {
    if (this.showReasonOptions && !this.reasonOption.value) {
      this.showReasonError = true;
      return;
    }

    this.showReasonError = false;
    this.ref.close(this.reason.value ? {reason: this.reason.value} : true);
  }

  cancel() {
    if (this.immutableData) this.ref.close(this.immutableData);
    this.ref.close();
  }

  closeModal(): void {
    this.ref.close();
  }

  get reasonOption(): FormControl {
    return this.reasonForm.get('reasonOption') as FormControl;
  }

  get reason(): FormControl {
    return this.reasonForm.get('reason') as FormControl;
  }
}
