import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./feature/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'office',
    loadChildren: () => import('./feature/office/office.module').then(m => m.OfficeModule)
  },
  {
    path: 'providers',
    loadChildren: () => import('./feature/providers/providers.module').then(m => m.ProvidersModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./feature/test/test.module').then(m => m.TestModule)
  },
  {
    path: 'recepts',
    loadChildren: () => import('./feature/recepts/recepts.module').then(m => m.ReceptsModule)
  },
  {
    path: 'e-consultations',
    loadChildren: () => import('./feature/e-consultations/e-consultations.module').then(m => m.EConsultationsModule)
  },
  {
    path: 'disability-leaves',
    loadChildren: () => import('./feature/disabilitity-leaves/disability-leaves-routing.module').then(m => m.DisabilityLeavesRoutingModule)
  },
  {
    path: 'open-cases',
    loadChildren: () => import('./feature/open-cases/open-cases.module').then(m => m.OpenCasesModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./feature/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'doctors',
    loadChildren: () => import('./feature/doctors/doctors.module').then(m => m.DoctorsModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./feature/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./feature/services/services.module').then(m => m.ServicesModule)
  },
  {
    path: '',
    loadChildren: () => import('./feature/office/office.module').then(m => m.OfficeModule),
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule { }
