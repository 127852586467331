import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ToastService } from "@core/services/toast.service";
import { LocationService } from "@core/services/location.service";
import { IAddress } from "@core/interfaces/address.interface";

@Component({
  selector: 'medis-address-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        [id]="formControlName"
        class="w-full"
        styleClass="w-full"
        [ngClass]="{'ng-invalid ng-dirty': formSubmitted && required && !selectedAddress}"
        appendTo="body"
        [options]="addressList"
        [filter]="addressList.length > 15"
        filterBy="fullAddress"
        [(ngModel)]="selectedAddress"
        (onChange)="onSelect($event.value)"
        optionValue="addressId"
        optionLabel="fullAddress"
        />
      <label [for]="formControlName">{{ labelName }}</label>
    </p-floatLabel>
    @if (formSubmitted && required && !selectedAddress) {
      <small class="p-error my-2">{{ labelName }} on kohustuslik</small>
    }
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSelectListComponent),
      multi: true
    }
  ]
})
export class AddressSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() onlyWorkAddresses: boolean = false;
  @Input() formControlName: string = '';
  @Input() labelName: string = '';
  @Input() formSubmitted: boolean = false;
  @Input() required: boolean = false;

  addressList: IAddress[] = [];
  selectedAddress: any;
  isDisabled = false;

  constructor(private locationService: LocationService, private toastService: ToastService) { }

  ngOnInit(): void {
    const methodName = this.onlyWorkAddresses ? 'getWorkAddresses' : 'getAllAddresses';
    this.locationService[methodName]().subscribe({
      next: resolve => {
        this.addressList = resolve;
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.selectedAddress = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: any) {
    this.selectedAddress = value;
    this.onChange(value);
    this.onTouched();
  }
}
