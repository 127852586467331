<p-pickList
  [source]="sourceServices"
  [target]="targetServices"
  sourceHeader="Saadaval"
  targetHeader="Valitud"
  [dragdrop]="true"
  [responsive]="true"
  [showSourceControls]="false"
  [showTargetControls]="false"
  [stripedRows]="true"
  (onMoveToTarget)="onTargetChange($event)"
  (onMoveToSource)="onTargetChange($event)"
  (onMoveAllToSource)="onTargetChange($event)"
  (onMoveAllToTarget)="onTargetChange($event)"
  filterBy="name"
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  breakpoint="1400px">
  <ng-template let-service pTemplate="item">
    <div class="flex flex-wrap p-2 align-items-center gap-3">
      <div class="flex-1 flex flex-column gap-2">
        <span class="font-bold">{{ service.name }}</span>
      </div>
    </div>
  </ng-template>
</p-pickList>
@if (targetServices.length < 1 && formSubmitted && required) {
  <small class="p-error">
    Vähemalt üks teenus peab olema lisatud
  </small>
}
