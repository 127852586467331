import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PresentModalComponent } from "@shared/modals/present/present.modal.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { DashboardService } from "@core/services/dashboard.service";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IService } from "@core/interfaces/service.interface";
import { OrderTypesService } from "@core/services/order-types.service";
import { ListService } from "@core/services/list.service";
import { OrderServiceService } from "@core/services/order-service.service";
import { Router } from "@angular/router";

@Component({
  selector: 'medis-patients-table',
  providers: [DialogService],
  templateUrl: './patients-table.component.html',
  styleUrls: ['./patients-table.component.scss'],
})
export class PatientsTableComponent implements OnDestroy {
  @Input() data: IOrderServiceTime[] = [];
  @Input() bookingDate: Date = new Date();
  @Input() service!: IService;

  @Output() updateList = new EventEmitter<{time: IOrderServiceTime, status: OrderServiceStatus}>();

  orderTypes: any = [];
  ref: DynamicDialogRef | undefined;

  constructor(
    private dashboardService: DashboardService,
    private dialogService: DialogService,
    private orderServiceService: OrderServiceService,
    private orderTypesService: OrderTypesService,
    private listService: ListService,
    private router: Router,
    ) {
  }

  openCommentsByOrder = (orderServiceTime: IOrderServiceTime | null) => {
    this.orderServiceService.openCommentsByOrder(orderServiceTime);
  };

  presentReady(time: IOrderServiceTime): boolean {
    const status = time.orderServiceTimeStatus ?? time.orderServiceStatus;
    return !!status && [OrderServiceStatus.CONFIRMED, OrderServiceStatus.IN_SERVICE_QUEUE, OrderServiceStatus.IN_SERVICE_QUEUE, OrderServiceStatus.IN_PROVIDER_QUEUE].includes(status);
  }

  openPatient(patientId: number){
    this.router.navigate([`/patients/view/${patientId}`]);
  }

  convertNameToProperCase(name: string | undefined): string {
    if(!name) {
      return '';
    }
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }


  setPresent(time: IOrderServiceTime): void {
    if (this.visitTypeInWorkLists()) {
      this.dashboardService.setOrderServiceStatus(time.orderServiceId, { orderServiceStatus: OrderServiceStatus.PRESENT })
        .subscribe({
        next: () => {
          console.log('status changed');
        },
        error: err => {
          console.error(err)
        }
      })
    } else {
      this.ref = this.dialogService.open(
        PresentModalComponent,
        {
          header: 'Kohaloleku märkimine',
          width: '80vw',
          data: { patientId: time.patientId, orderServiceTime: time }
        });
      // $uibModal
      //   .open({
      //     animation: conf.bootstrap.modal.animation,
      //     component: PRESENT_MODAL_COMPONENT_NAME,
      //     size: 'xl',
      //     resolve: {
      //       orderServiceTime: () => _orderServiceQt,
      //       patientId: () => order.patientId,
      //     }
      //   }).result
      //   .then(res => {
      //     if (vm.orderServiceQt && vm.orderServiceQt.orderServiceTimeId) {
      //       return dashboardService.setOrderServiceTimeStatus({orderServiceTimeId: vm.orderServiceQt.orderServiceTimeId}, {orderServiceStatus: present}).$promise;
      //     } else {
      //       return dashboardService.setOrderServiceStatus({orderServiceId}, {orderServiceStatus: present}).$promise;
      //     }
      //
      //   })
      //   .then(result => {
      //     vm.refresh(result);
      //   });
    }
  }

  visitTypeInWorkLists = () => {
    this.orderTypes = this.orderTypesService.getOrderTypes(this.listService.listCodesMap.value);
    if (!this.service) return false;
    const listData = this.service ? this.service.listData : null;
    return listData && listData.some(x => [
      this.orderTypes.home_visit.serviceSearchFilter.visitType,
      this.orderTypes.other.serviceSearchFilter.visitType,
      this.orderTypes.phone_contact.serviceSearchFilter.visitType
    ].includes(x.value));
  };

  isFutureDateSelected = () => {
    const now = new Date();
    now.setHours(0,0,0,0)
    return now.getDate() < this.bookingDate.getDate();
  }

  onTimeCancel(timeData: {time: IOrderServiceTime, status: OrderServiceStatus}): void {
    this.updateList.emit(timeData);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  protected readonly OrderServiceStatus = OrderServiceStatus;
}
