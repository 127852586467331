import { Injectable } from '@angular/core';
import { DialogService } from "primeng/dynamicdialog";
import { ModalSize } from "@core/enums/modalSize";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private dialogService: DialogService) {
  }

  public openModal(settings: ModalSettings): any {
    let sizes = this.mapSizes(settings.size);

    let modalOptions: any = {
      width: sizes.width,
      header: settings.header,
      height: sizes.height,
      data: settings.data,
      modal: true,
      breakpoints: {
        '960px': sizes.breakPoint960,
        '640px': sizes.breakPoint640
      }
    };

    if (settings.disableClose) {
      modalOptions.closable = false;
      modalOptions.dismissableMask = false;
    }

    return this.dialogService.open(settings.component, modalOptions);
  }

  private mapSizes(size: ModalSize|undefined): ModalServiceSize {
    switch (size) {
      case ModalSize.EXTRA_LARGE:
        return {
          width: '50vw',
          height: 'calc(100wh-3rem)',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
      case ModalSize.LARGE:
        return {
          width: '75rem',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
      case ModalSize.SMALL:
        return {
          width: '40rem',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
      default:
      case ModalSize.MEDIUM:
        return {
          width: '50vw',
          breakPoint960: '50vw',
          breakPoint640: '50vw',
        };
    }
  }
}

interface ModalSettings {
  component: any;
  size?: ModalSize;
  data?: any;
  header?: any;
  disableClose?: boolean;
}

interface ModalServiceSize {
  width: string;
  height?: string|undefined;
  breakPoint960: string;
  breakPoint640: string;
}

