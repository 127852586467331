import { Pipe, PipeTransform } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";

@Pipe({
  name: 'channelName',
  standalone: true
})
export class ChannelNamePipe implements PipeTransform {
  private channelMap: IMapping = {
    1: 'MEDIS',
    2: 'MIS',
    3: 'MDR',
    4: 'ÜDR',
    5: 'TAASTUSRAVI',
    6: 'MEDIS HGJ',
    Admin: 'MEDIS',
    ServiceDesk: 'MIS',
    Web: 'MDR',
    TEHIK: 'ÜDR',
    Physiotherapy: 'TAASTUSRAVI',
    MedisHgj: 'MEDIS HGJ'
  };

  transform(channel: any): string | undefined {
    return this.channelMap[channel];
  }

}
