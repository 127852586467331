import { Injectable } from '@angular/core';
import { AuthService } from "./auth.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ExternalMedicalCaseService {

  constructor(private authService: AuthService) { }

  open(data: {
    patient: { id: number },
    doctor: { id: number, departmentId: number },
    orderService: { id: number, bookingTime: string, service: { id: number, name: string } },
    medicalCaseId?: number
  }): void {
    const f = document.createElement("form");
    f.setAttribute('id', 'medicalcaseopenform');
    f.setAttribute('name', 'medicalcaseopenform');
    f.setAttribute('method', "post");
    f.setAttribute('target', "_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'external');

    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("patient.id", data.patient.id, f);
    this.addInput("doctor.id", data.doctor.id, f);
    this.addInput("doctor.departmentId", data.doctor.departmentId, f);
    this.addInput("orderService.id", data.orderService.id, f);
    this.addInput("orderService.bookingTime", data.orderService.bookingTime, f);
    this.addInput("orderService.service.id", data.orderService.service.id, f);
    this.addInput("orderService.service.name", data.orderService.service.name, f);
    if (data.medicalCaseId) {
      this.addInput("medicalCaseId", data.medicalCaseId, f);
    }

    document.body.appendChild(f);

    f.submit();
  }

  openPatient(patientId: number) {
    let f = document.createElement("form");
    f.setAttribute('id','patientopenform');
    f.setAttribute('name','patientopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("patientId", patientId, f);

    document.body.appendChild(f);

    f.submit();
  }

  openPrescription(prescriptionId: number){
    let f = document.createElement("form");
    f.setAttribute('id','prescriptionsopenform');
    f.setAttribute('name','prescriptionsopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("prescriptionId", prescriptionId, f);

    document.body.appendChild(f);

    f.submit();
  }

  opencCoifw(coifwId: number){
    let f = document.createElement("form");
    f.setAttribute('id','coifwopenform');
    f.setAttribute('name','coifwopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("coifwId", coifwId, f);

    document.body.appendChild(f);

    f.submit();
  }

  openCase(caseId: number){
    let f = document.createElement("form");
    f.setAttribute('id','caseopenform');
    f.setAttribute('name','caseopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'externalopen');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("caseId", caseId, f);

    document.body.appendChild(f);

    f.submit();
  }

  openMedicalCaseForm(){
    let f = document.createElement("form");
    f.setAttribute('id','medicalcaseopenform');
    f.setAttribute('name','medicalcaseopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'external');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("patient.id", 245113, f);
    this.addInput("doctor.id", 12057, f);
    this.addInput("doctor.departmentId", 0, f);
    this.addInput("orderService.id", 0, f);
    this.addInput("orderService.bookingTime", "2024-03-11T00:00:00", f);
    this.addInput("orderService.service.id", 0, f);
    this.addInput("orderService.service.name", null, f);
    this.addInput("toApplication", "MCASE", f);
    this.addInput("toModule", "mcase-case-dashboard", f);

    document.body.appendChild(f);

    f.submit();
  }

  openPhysiotherapyForm(){
    let f = document.createElement("form");
    f.setAttribute('id','physioopenform');
    f.setAttribute('name','physioopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'Medis');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("toApplication", "PHYSIO", f);

    document.body.appendChild(f);

    f.submit();
  }

  openStudyForm(){
    let f = document.createElement("form");
    f.setAttribute('id','proceduresopenform');
    f.setAttribute('name','proceduresopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'Medis');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("toApplication", "PHYSIO", f);
    this.addInput("ToModule", "examination", f);

    document.body.appendChild(f);

    f.submit();
  }

  openProceduresForm(){
    let f = document.createElement("form");
    f.setAttribute('id','proceduresopenform');
    f.setAttribute('name','proceduresopenform');
    f.setAttribute('method',"post");
    f.setAttribute('target',"_blank");
    f.setAttribute('action', environment.externalMedicalCaseUrl + 'Medis');
    this.addInput("token", this.authService.getAccessToken(), f);
    this.addInput("toApplication", "PHYSIO", f);
    this.addInput("ToModule", "procedure", f);

    document.body.appendChild(f);

    f.submit();
  }

  private addInput(name: string, value: any, form: HTMLFormElement): void {
    const input = document.createElement("input");
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value.toString());
    form.appendChild(input);
  }
}
