import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IPerson } from "../interfaces/person.interface";
import { IOrderServiceTime } from "../interfaces/order-service-time.interface";
import { IOrderServiceQueue } from "../interfaces/order-service-queue.interface";
import { Gender } from "../enums/gender.enum";
import { IPersonService } from "../interfaces/person-service.interface";
import { IRoom } from "../interfaces/room.interface";
import { IServicePerson } from "../interfaces/service-person.interface";
import { IDepartment } from "@core/interfaces/department.interface";

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/persons`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/persons`;
  }

  public get(id: number): Observable<IPerson> {
    return this.http.get<IPerson>(`${this.BACKEND_URL}/${id}`);
  }

  public save(data: any): Observable<IPerson> {
    return this.http.post<IPerson>(`${this.BACKEND_URL}/`, data);
  }

  public forceSave(provider: IPerson): Observable<IPerson> {
    return this.http.post<IPerson>(`${this.BACKEND_URL}/force`, provider);
  }

  public getAvailableProviders(date: string, from: string, to: string, planTimeId: number): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers/available/${date}/${from}/${to}/${planTimeId}`);
  }

  public getBookings(personId: number, startDate: string, endDate: string, serviceId: number): Observable<IOrderServiceTime[]> {
    return this.http.get<IOrderServiceTime[]>(`${this.BACKEND_URL}/ordertimes/${personId}/${startDate}/${endDate}/${serviceId}`);
  }

  public getQueues(personId: number, startDate: string, endDate: string, serviceId: number): Observable<IOrderServiceQueue[]> {
    return this.http.get<IOrderServiceQueue[]>(`${this.BACKEND_URL}/orderqueues/${personId}/${startDate}/${endDate}/${serviceId}`);
  }

  public getGenders(): Observable<Gender[]> {
    return this.http.get<Gender[]>(`${this.BACKEND_URL}/genders`);
  }

  public getProvidersByProfessionTypeCode(professionTypeCode: string): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers/profession/${professionTypeCode}`);
  }

  public search(search: string, type: string): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/${search}/${type}`);
  }

  public getProviders(params: HttpParams): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/providers`, {params: params});
  }

  public getProvidersForSelect(): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.OLD_BACKEND_URL}/providers/select?includeNonActive=true`);
  }

  public getActiveProvidersForSelect(): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.OLD_BACKEND_URL}/providers/select`);
  }

  public getProviderServices(personId: number): Observable<IPersonService[]> {
    return this.http.get<IPersonService[]>(`${this.BACKEND_URL}/${personId}/services/`);
  }

  public getPersonBasic(personId: number): Observable<IPerson> {
    return this.http.get<IPerson>(`${this.BACKEND_URL}/basic/${personId}`);
  }

  public getCalendar(params: HttpParams): Observable<IPerson> {
    return this.http.get<IPerson>(`${this.BACKEND_URL}/calendar`, {params});
  }

  public getRooms(personId: number): Observable<IRoom[]> {
    return this.http.get<IRoom[]>(`${this.BACKEND_URL}/${personId}/rooms`);
  }

  public updatePersonService(personService: IServicePerson): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/newservices`, personService);
  }

  public updateServicesForce(personService: IServicePerson): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/newservices/force`, personService);
  }

  public isDoctorCodeExists(personId: number, doctorCode: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/doctorCodeExists/${personId}/${doctorCode}`);
  }

  public isCodeExists(personId: number, code: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/codeExists/${personId}/${code}`);
  }

  public getForPlanTime(providerId: number): Observable<IPerson> {
    return this.http.get<IPerson>(`${this.BACKEND_URL}/forPlanTime/${providerId}`);
  }

}
