import { Component, Input, ViewChild } from '@angular/core';
import { Table } from "primeng/table";
import { TableColumn } from "@core/interfaces/table-column.interface";

@Component({
  selector: 'medis-data-table',
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss'
})
export class DataTableComponent {
  @Input() data: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() filterFields: string[] = [];
  @Input() addButtonLabel: string = '';

  @ViewChild('dt') dt: Table | undefined;

  applyFilterGlobal($event: any, stringVal: string) {
    if(this.dt) {
      this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }
  }

  isBoolean(data: any): boolean {
    return typeof data === 'boolean';
  }

  createHref(row: any, col: TableColumn): string {
    let template: any = col.hrefTemplate ?? 'edit/:id';
    return template.split(':id').join(row[col.idKey!]);
  }

  protected readonly Array = Array;
}
