import { Injectable } from '@angular/core';
import {Gender} from "../enums/gender.enum";
import {ListIds} from "../enums/list-ids.enum";

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  getBirthdayFromPersonalCode(code: string): Date {
    let year = parseInt(code.substring(1, 3)),
      month = parseInt(code.substring(3, 5).replace(/^0/, '')) - 1,
      day = code.substring(5, 7).replace(/^0/, ''),
      firstNumber = code.charAt(0);

    if (firstNumber === '1' || firstNumber === '2') {
      year += 1800;
    } else if (firstNumber === '3' || firstNumber === '4') {
      year += 1900;
    } else if (firstNumber === '5' || firstNumber === '6') {
      year += 2000;
    } else {
      year += 2100;
    }

    return new Date(year, month, +day);
  }

  getAge(birthday: Date): number {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getGenderFromIdCode(code: string) {
    const firstNumber = code.charAt(0);
    switch (firstNumber) {
      case '1':
      case '3':
      case '5':
        return Gender.MALE;
      case '2':
      case '4':
      case '6':
        return Gender.FEMALE;
    }
    return null;
  };

  validatePersonCode(code: string): boolean {
    if (!code || code.length !== 11) {
      return false;
    }
    const control = this.getPersonCodeControlNumber(code);
    if (control !== parseInt(code.charAt(10))) {
      return false;
    }

    const year = Number(code.substring(1, 2));
    const month = Number(code.substring(3, 2));
    const day = Number(code.substring(5, 2));
    const birthDate = this.getBirthdayFromPersonalCode(code);
    return year === birthDate.getFullYear() % 100 && birthDate.getMonth() + 1 === month && day === birthDate.getDate();
  };

  getPersonCodeControlNumber(code: string): number {
    let multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1],
      multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3],
      mod,
      total = 0;

    for (let i = 0; i < 10; i++) {
      total += +code.charAt(i) * multiplier1[i];
    }
    mod = total % 11;

    total = 0;
    if (10 === mod) {
      for (let i = 0; i < 10; i++) {
        total += +code.charAt(i) * multiplier2[i];
      }
      mod = total % 11;
      if (10 === mod) {
        mod = 0;
      }
    }
    return mod;
  }

  getIsSubscriberMedicalCase(service: any, provider: any): boolean {
    const specialityId = service.listData.find((x: any) => x.type === ListIds.SPECIALTY)?.value;
    return service.serviceCategoryId !== 13 || provider?.profession?.generalListId === specialityId;
  }

  isEmpty(value: any): boolean {
    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  }
}
