import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {IAbsence} from "../interfaces/absence.interface";
import {Observable} from "rxjs";
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  public error(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'error',
      summary: 'Viga',
      detail: message ?? "Default message",
      sticky: true
    });
  }

  public message(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message ?? "Default message",
      sticky: true
    });
  }

  public success(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message ?? "Default message",
      sticky: true
    });
  }

  public warn(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'warn',
      summary: 'Warn',
      detail: message ?? "Default message",
      sticky: true
    });
  }

  public contrast(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'contrast',
      summary: 'Contrast',
      detail: message ?? "Default message",
      sticky: true
    });
  }

  public secondary(message: string|undefined|null): void {
    return this.messageService.add({
      severity: 'secondary',
      summary: 'Secondary',
      detail: message ?? "Default message",
      sticky: true
    });
  }
}
