import { Component, Input, ContentChild, TemplateRef, OnInit } from '@angular/core';

@Component({
  selector: 'medis-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabs: MedisTab[] = [];
  activeIndex = 0;

  ngOnInit() {

    if (this.tabs.length > 0) {

      this.tabs.forEach((value, index, array) => {
        if (value.isActive) {
          this.activeIndex = index;
        }
      })

    }

  }

  switchHeaders(event: any) {

    let index : number = event.index;
    if(this.tabs[index].action){
      this.tabs[index].action();
    }


  }
}

export interface MedisTab {
  title: string;
  content: TemplateRef<any> | null;
  action?: any;
  isActive?: boolean;
}
