import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";

@Component({
  selector: 'app-multiple-patient-search',
  templateUrl: './multiple-patient-search.component.html'
})
export class MultiplePatientSearchComponent {
  @Input() patients: IPatient[] = [];
  @Output() selectPatient = new EventEmitter<IPatient>();

  choosePatient(patient: IPatient) {
    this.selectPatient.emit(patient);
  }

  getStatusInfo(isInsured: boolean) {
    return isInsured
      ? { class: 'tag--green', name: 'KINDLUSTATUD' }
      : { class: 'tag--red', name: 'KINDLUSTAMATA' };
  }

  convertNameToProperCase(name: string | undefined): string {
    if(!name) {
      return '';
    }
    return name
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
