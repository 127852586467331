import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { SharedModule } from "@shared/shared.module";
import { AuthService } from "@core/services/auth.service";
import { AuthInterceptorService } from "@core/interceptors/auth-interceptor.service";
import { DateFormatInterceptor } from "@core/interceptors/date-format-interceptor";
import { I18nModule } from "./i18n.module";
import { provideRouter, Router, RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MessageService } from "primeng/api";
import { ToastService } from "@core/services/toast.service";
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEt from '@angular/common/locales/et';
import { HeaderModule } from "./feature/header/header.module";
import { DialogService } from "primeng/dynamicdialog";

registerLocaleData(localeEt, 'et');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    I18nModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderModule,
  ],
  providers: [
    MessageService,
    DialogService,
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
      deps: [Router, AuthService, ToastService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateFormatInterceptor,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'et'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
