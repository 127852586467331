import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IOrder } from "../interfaces/order.interface";
import { IOrderService } from "../interfaces/order-service.interface.interface";
import { IOrderServiceTimeQueueCancel } from "../interfaces/order-service-time-queue-cancel.interface";
import { IBookingTime } from "../interfaces/booking-time.interface";
import { IBookingDates } from "../interfaces/booking-dates.interface";
import { IPerson } from "../interfaces/person.interface";
import { IOrderRow } from "../interfaces/order-row.interface";
import { IOrderServiceSearchResultRow } from "../interfaces/order-service-search-result-row.interface";
import { IOrderServiceSearchFilters } from "../interfaces/order-service-search-filters.interface";
import { IReferral } from "../interfaces/referral.interface";
import { IOrderServiceLog } from "../interfaces/order-service-log.interface";
import { IOrderServiceNotificationLog } from "../interfaces/order-service-notification-log.interface";
import { IOrderServiceComment } from "../interfaces/order-service-comment.interface";
import { IOrderLog } from "../interfaces/order-log.interface";
import { IOrderToPosInvoiceResponse } from "../interfaces/order-to-pos-invoice-response.interface";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/orders`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/orders`;
  }

  public get(orderId: number): Observable<IOrder> {
    return this.http.get<IOrder>(`${this.BACKEND_URL}/${orderId}`);
  }

  public save(order: IOrder): Observable<IOrder> {
    return this.http.post<IOrder>(`${this.BACKEND_URL}/save`, order);
  }

  public updateOrderService(orderService: IOrderService): Observable<IOrderService> {
    return this.http.post<IOrderService>(`${this.BACKEND_URL}/service/save`, orderService);
  }

  public deleteOrderService(orderServiceId: number): Observable<any> {
    return this.http.delete<any>(`${this.BACKEND_URL}/service/${orderServiceId}`);
  }

  public cancelOrderServiceTimeQueue(orderServiceTimeQueueCancel: IOrderServiceTimeQueueCancel): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/cancel`, orderServiceTimeQueueCancel);
  }

  public getOrderBookingTimes(orderId: number): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/bookingtimes/${orderId}`);
  }

  public getServiceFirstBookingTimes(serviceId: number, financialSourceId: number): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/bookingtimes/${serviceId}/${financialSourceId}`);
  }

  public getPaymentMethods(): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/paymentmethods`);
  }

  public getServiceBookingDates(params: HttpParams): Observable<IBookingDates> {
    return this.http.get<IBookingDates>(`${this.BACKEND_URL}/bookingdates`, {params});
  }

  public getBookingTimesProviders(params: HttpParams): Observable<IPerson[]> {
    return this.http.get<IPerson[]>(`${this.BACKEND_URL}/bookingtimes/providers`, {params});
  }

  public getServiceBookingTimes(date: string, patientId: number, serviceId: number, addressId: number, financialSourceId: number): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/bookingtimes/date/${date}/${serviceId}/${patientId}/${addressId}/${financialSourceId}`);
  }

  public getAllOrders(): Observable<IOrderRow[]> {
    return this.http.get<IOrderRow[]>(`${this.BACKEND_URL}/current`);
  }

  public searchOrders(params: HttpParams): Observable<IOrderServiceSearchResultRow[]> {
    return this.http.get<IOrderServiceSearchResultRow[]>(`${this.BACKEND_URL}/search`, {params});
  }

  public searchOrdersByOrderDate(params: HttpParams): Observable<IOrderServiceSearchResultRow[]> {
    return this.http.get<IOrderServiceSearchResultRow[]>(`${this.BACKEND_URL}/searchByOrderDate`, {params});
  }

  public getAllFilters(): Observable<IOrderServiceSearchFilters> {
    return this.http.get<IOrderServiceSearchFilters>(`${this.BACKEND_URL}/getFilters`);
  }

  public getReferral(referralId: number): Observable<IReferral> {
    return this.http.get<IReferral>(`${this.BACKEND_URL}/referral/${referralId}`);
  }

  public cancelReferral(referralId: number): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/referral/cancel/${referralId}`);
  }

  public getOrderService(serviceId: number): Observable<IOrderService> {
    return this.http.get<IOrderService>(`${this.BACKEND_URL}/services/${serviceId}`);
  }

  public getOrderServiceLogs(params: HttpParams): Observable<IOrderServiceLog[]> {
    return this.http.get<IOrderServiceLog[]>(`${this.OLD_BACKEND_URL}/orderServices/orderServiceLogs`, {params});
  }

  public getOrderServiceNotificationLogs(params: HttpParams): Observable<IOrderServiceNotificationLog[]> {
    return this.http.get<IOrderServiceNotificationLog[]>(`${this.OLD_BACKEND_URL}/orderServices/orderServiceNotificationLogs`, {params});
  }

  public getOrderServiceComments(orderServiceId: number): Observable<IOrderServiceComment[]> {
    let params = new HttpParams();
    if (orderServiceId) {
      params = params.set('orderServiceId', orderServiceId)
    }
    return this.http.get<IOrderServiceComment[]>(`${this.OLD_BACKEND_URL}/orderServices/orderServiceComments`, {params});
  }

  public saveOrderServiceComment(params: HttpParams): Observable<boolean> {
    return this.http.get<boolean>(`${this.OLD_BACKEND_URL}/orderServices/saveOrderServiceComment`, {params});
  }

  public deleteOrderServiceComment(params: HttpParams): Observable<boolean> {
    return this.http.delete<boolean>(`${this.OLD_BACKEND_URL}/orderServices/deleteOrderServiceComment`, {params});
  }

  public createOrderOtherVisits(params: HttpParams): Observable<IOrder> {
    return this.http.get<IOrder>(`${this.BACKEND_URL}/createOrderOtherVisits`, {params});
  }

  public getOrderLogs(orderId: number): Observable<IOrderLog[]> {
    return this.http.get<IOrderLog[]>(`${this.OLD_BACKEND_URL}/orders/${orderId}/orderLogs`);
  }

  public getFirstBookingTimes(serviceId: number, patientId: number, addressId: number, providerId: number, limit: number): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/firstbookingtimes/${serviceId}/${patientId}/${addressId}/${providerId}/${limit}`);
  }

  public changePatient(orderId: number, patientId: number): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/changepatient/${orderId}/${patientId}`);
  }

  public getInitExternalMedicalCaseData(params: HttpParams): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/initExternalMedicalCaseData`, {params});
  }

  public orderToPosInvoice(registryCode: string, orderNumber: number): Observable<IOrderToPosInvoiceResponse> {
    return this.http.get<IOrderToPosInvoiceResponse>(`${this.BACKEND_URL}/orderToPosInvoice/${registryCode}/${orderNumber}`);
  }

  public sendServicesPaymentLink(params: HttpParams): Observable<boolean> {
    return this.http.post<boolean>(`${this.BACKEND_URL}/payment/send`, {}, {params});
  }
}
