<div class="modal-body">
<!--  <order-patient-data patient="patient"></order-patient-data>-->

  @if (patientError && patientDataInvalid) {
    <div class="alert alert-danger">
      Palun täitke patsiendi andmed!
    </div>
  }

  <div class="panel">
    <div class="panel-body clearfix">
      <div class="row">
        <div class="col-md-12">
          <h3 class="title-hero">
            Vastuvõtuinfo
          </h3>
        </div>
        <div class="col-md-12 mt-2">
          <p-table [value]="[orderServiceTime]" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped">
            <ng-template pTemplate="header">
            <tr>
              <th>Kuupäev</th>
              <th>Kellaaeg</th>
              <th>Teenus</th>
              <th>Osutaja</th>
              <th>Tellimus</th>
              @if (orderServiceTime && orderServiceTime.financialSourceName === FinancialSource.PERSON) {
                Hind
              }
              <th>Ruum</th>
              <th>Asukoht</th>
              <th>Saatekiri</th>
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-orderServiceTime>
            <tr>
              <td>{{ orderServiceTime.date | dateTimeDisplay: 'DD.MM.YYYY' }}</td>
              <td>{{ orderServiceTime.range }}</td>
              <td>{{ orderServiceTime.serviceName }}
                <medis-financial-source-label [short]="true" [financialSource]="orderServiceTime.financialSourceName"></medis-financial-source-label>
              </td>
              <td>{{ orderServiceTime.providerName }}</td>
              <td>{{ orderServiceTime.orderId }}</td>
              @if (orderServiceTime && orderServiceTime.financialSourceName === FinancialSource.PERSON) {
                <b>{{ orderServiceTime.price }} €</b>
              }
              <td>{{ orderServiceTime.roomCode }}</td>
              <td>{{ orderServiceTime.address}}</td>
              <td>
                @if (selectedReferral) {
                  <a href (click)="openReferral()">{{selectedReferral.documentNr}}</a>
                } @else {
<!--                  add order-referral-status-label component-->
<!--                  <small ng-if="!selectedReferral"><order-referral-status-label status="selectedReferral.referralStatus"></order-referral-status-label></small>-->
<!--                  {{ selectedReferral.status }}-->
                }
              </td>
            </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  @if (showETervisReferrals) {
    <div class="panel">
      <div class="panel-body clearfix">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title-hero mb-0">
              Sidumine saatekirjaga
              <button class="btn btn-primary btn-sm pull-right" (click)="showReferrals = true">Vaata saatekirju</button>
            </h3>
          </div>
          @if (showReferrals) {
            <div class="col-md-12">
<!--              add referral-select component-->
<!--              <referral-select selected-referral="selectedReferral" service-id="service.serviceId" patient-id="patient.patientId" on-referral-select="onReferralSelect(model)"></referral-select>-->
            </div>
          }
        </div>
      </div>
    </div>
  }

  @if (unpaidService && unpaidService.orderId && unpaidService.invoiceStatus != 'Unneeded') {
    <div class="panel">
      <div class="panel-body clearfix">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title-hero">Arved</h3>
          </div>
          <div class="col-md-12">
            <p-table [value]="[unpaidService]" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th>Kuupäev</th>
                  <th>Kellaaeg</th>
                  <th>Teenus</th>
                  <th>Arst</th>
                  <th>Aadress</th>
                  <th>Kabinet</th>
                  <th>Tasuda(€)</th>
                  <th>Staatus</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-unpaidService>
              <tr>
                <td>{{ unpaidService.date | dateTimeDisplay: 'DD.MM.YYYY' }}</td>
                <td>{{ unpaidService.time | time }}</td>
                <td>{{ unpaidService.serviceName }}</td>
                <td>{{ unpaidService.providerName }}</td>
                <td>{{ unpaidService.address | patientContact }}</td>
                <td>{{ unpaidService.room.codeName }}</td>
                <td>{{ unpaidService.price }}</td>
                <td>
                  <medis-order-service-invoice-status [status]="unpaidService.invoice.invoiceStatus"></medis-order-service-invoice-status>
                </td>
                @if (!(unpaidService.invoice.invoiceStatus === InvoiceStatus.COMPLETED || unpaidService.invoice.invoiceStatus === InvoiceStatus.PREPAID)) {
                  <td>
                    <p-button label="Mine maksma" (click)="createInvoiceAndPay()" disabled="creatingInvoice" />
                  </td>
                }
              </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  }

  @if (!noPaymentProcessComplete && visitPriceInfo.status !== VisitPriceStatus.UNNEEDED) {
    <form [formGroup]="reasonForm">
    <div class="panel">
      <div class="panel-body clearfix">
        <div class="row mb-3">
          <div class="col-sm-3">
            <div class="float-left">
              <p-checkbox
                formControlName="noPayment"
                [binary]="true"
                (onChange)="toggleMustPay()"
                inputId="noPayment"></p-checkbox>
<!--              <div id="noPayment" class="switch-toggle" style="display: block;" (click)="toggleMustPay()"></div>-->
            </div>
            <label class="float-left ml-3">Patsient ei pea tasuma visiiditasu</label>
          </div>
        </div>
        @if (noPayment) {
          <div class="flex flex-column gap-2 mr-1">
            <label for="exemptionReason">Lisa põhjus</label>
            <p-dropdown
              id="exemptionReason"
              styleClass="exemption-reason-dropdown"
              [options]="exemptionReasons"
              formControlName="exemptionReason"
              optionValue="value"
              optionLabel="label"/>
          </div>
        }

        @if (visitPriceInfoChanged) {
          <div class="row">
            <div class="col-sm-2">
              <p-button label="Kinnita"
                        [disabled]="noPayment && !visitPriceInfo.exemptionReason"
                        (click)="saveVisitPriceInfo()" />
            </div>
          </div>
        }
      </div>
    </div>
    </form>
  }


  @if (paymentInfoForm) {
  <div class="panel">
    <div class="panel-body clearfix">
      <div class="row">
        <div class="col-md-12">
          <h3 class="title-hero">Makseinfo
          </h3>
        </div>
          <form [formGroup]="paymentInfoForm">
            <div class="flex flex-column gap-2 mr-1">
              <label for="financialSource">Maksja</label>
              <p-dropdown
                id="financialSource"
                [options]="serviceFinancialSources"
                formControlName="financialSource"
                optionValue="financialSourceId"
                optionLabel="financialSourceName"
                appendTo="body"
                (onChange)="onFinSourceChange()"/>
            </div>
            @if (financialSourceCode !== FinSourceName.PayerHealthInsuranceFund) {
              <div class="row">
                <div class="col-md-6">
                  <p-button label="Kinnita" (click)="confirmPayer()" />
                </div>
              </div>
            }
          </form>
      </div>
    </div>
  </div>
  }
</div>
<div class="card flex gap-2 w-full justify-content-end">
  <p-button label="Katkesta" (click)="cancel()" size="small"/>
  <p-button label="Kohal" [disabled]="presentButtonDisabled()" (click)="submit()" size="small"/>
</div>
