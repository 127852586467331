import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IService } from "../interfaces/service.interface";
import { IBrochure } from "../interfaces/brochure.interface";
import { IEmployeeGroup } from "../interfaces/employee-group.interface";
import { IServiceCategory } from "../interfaces/service-category.interface";
import { IPersonService } from "../interfaces/person-service.interface";
import { IServiceLog } from "../interfaces/service-log.interface";
import { IServiceDetailsGraphData } from "../interfaces/service-details-graph-data.interface";
import { IServicesRosterReportData } from "../interfaces/services-roster-report-data.interface";
import { IGoods } from "../interfaces/goods.interface";

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/services`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/services`;
  }

  public getServices(params: HttpParams): Observable<IService[]> {
    return this.http.get<IService[]>(`${this.OLD_BACKEND_URL}`, {params});
  }

  public get(serviceId: number): Observable<IService> {
    return this.http.get<IService>(`${this.BACKEND_URL}/${serviceId}`);
  }

  public update(service: IService): Observable<IService> {
    return this.http.post<IService>(`${this.BACKEND_URL}`, service);
  }

  public getCategories(): Observable<IServiceCategory[]> {
    return this.http.get<IServiceCategory[]>(`${this.OLD_BACKEND_URL}/categories`);
  }

  public getBrochures(): Observable<IBrochure[]> {
    return this.http.get<IBrochure[]>(`${this.BACKEND_URL}/brochures`);
  }

  public getEmployeeGroups(): Observable<IEmployeeGroup[]> {
    return this.http.get<IEmployeeGroup[]>(`${this.BACKEND_URL}/employeegroups`);
  }

  public getProviderServices(personId: number): Observable<IPersonService[]> {
    return this.http.get<IPersonService[]>(`${this.BACKEND_URL}/person/${personId}`);
  }

  public saveCategory(serviceCategory: IServiceCategory): Observable<IServiceCategory> {
    return this.http.post<IServiceCategory>(`${this.BACKEND_URL}/categories`, serviceCategory);
  }

  public search(search: string, excludeType: number): Observable<IService[]> {
    return this.http.get<IService[]>(`${this.BACKEND_URL}/search/${search}/${excludeType}`);
  }

  public getServicesForPicker(params: HttpParams): Observable<IService[]> {
    return this.http.get<IService[]>(`${this.BACKEND_URL}/picker`, {params});
  }

  public getServicesForSelect(params?: HttpParams): Observable<IService[]> {
    return this.http.get<IService[]>(`${this.OLD_BACKEND_URL}/select`, {params});
  }

  public getServiceLogs(params: HttpParams): Observable<IServiceLog[]> {
    return this.http.get<IServiceLog[]>(`${this.BACKEND_URL}/logs`, {params});
  }

  public getGraphData(serviceId: number, fromDate: string, toDate: string): Observable<IServiceDetailsGraphData> {
    return this.http.get<IServiceDetailsGraphData>(`${this.BACKEND_URL}/${serviceId}/${fromDate}/${toDate}/graphdata`);
  }

  public getRosterReport(from: string, to: string, params: HttpParams): Observable<IServicesRosterReportData> {
    return this.http.get<IServicesRosterReportData>(`${this.BACKEND_URL}/rosterReport/${from}/${to}`, {params});
  }

  public getServicePrice(serviceId: number): Observable<number> {
    return this.http.get<number>(`${this.BACKEND_URL}/price/${serviceId}`);
  }

  public getSpecialServices(): Observable<IGoods[]> {
    return this.http.get<IGoods[]>(`${this.BACKEND_URL}/specialServices`);
  }
}
