import { Component, Input, OnInit } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { ButtonModule } from "primeng/button";
import { SplitButtonModule } from "primeng/splitbutton";
import { MenuModule } from "primeng/menu";
import { MenuItem } from "primeng/api";
import { NgIf } from "@angular/common";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IService } from "@core/interfaces/service.interface";
import { OrderTypesService } from "@core/services/order-types.service";
import { ListService } from "@core/services/list.service";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IOrderServicesData } from "@core/interfaces/order-services-data.interface";
import { IOrder } from "@core/interfaces/order.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { OrderReferralStatus } from "@core/enums/order-referral-status.enum";
import { ModalService } from "@core/services/modal.service";
import { ModalTableComponent } from "../../../feature/test/components/modal-table/modal-table.component";
import { ModalSize } from "@core/enums/modalSize";
import { HttpParams } from "@angular/common/http";
import { OrderService } from "@core/services/order.service";
import { forkJoin } from "rxjs";
import { IOrderServiceLog } from "@core/interfaces/order-service-log.interface";
import { OrderServiceLogStatus } from "@core/enums/order-service-log-status.enum";

@Component({
  selector: 'medis-order-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdownToggle,
    ButtonModule,
    SplitButtonModule,
    MenuModule,
    NgIf,
    OverlayPanelModule,
  ],
  templateUrl: './order-actions.component.html'
})
export class OrderActionsComponent implements OnInit {
  @Input() time!: IOrderServiceTime;
  @Input() service!: IService;
  @Input() orderService!: IOrderService;
  @Input() orderServiceData!: IOrderServicesData;
  @Input() order!: IOrder;
  @Input() futureOrders: boolean = false;

  orderTypes: any = [];
  actions: MenuItem[] | undefined;
  orderServiceStatus: OrderServiceStatus | null | undefined;
  patientIdCode!: string;
  patientMisCode!: number | null;
  nextOrderId!: number | null | undefined;
  messages: string[] = [];

  constructor(
    private orderTypesService: OrderTypesService,
    private listService: ListService,
    private modalService: ModalService,
    private orderServiceLog: OrderService,
    ) {
  }

  ngOnInit() {
    this.orderTypes = this.orderTypesService.getOrderTypes(this.listService.listCodesMap.value);
    this.orderServiceStatus = this.getOrderServiceStatus();
    this.patientIdCode = this.order?.patient?.idCode ?? this.time.patientIDcode;
    this.patientMisCode = this.order?.patient?.misCode ?? this.time.patientMisCode;
    this.nextOrderId = this.time?.nextOrderId ?? this.order?.nextOrderId;
    this.initActions();
  }

  private initActions(): void {
    this.actions = [
      { label: 'Ava tellimus', visible: true },
      { label: 'Prindi', visible: this.printActionVisible() },
      { label: 'Kommentaarid', visible: true },
      {
        label: this.orderServiceStatus === OrderServiceStatus.PREPARING ? 'Broneeri aeg' : 'Muuda aeg',
        visible: this.bookTimeActionVisible()
      },
      { label: 'Ava saatekirjad', visible: this.openReferralsActionVisible() },
      { label: 'Tühista aeg', visible: this.cancelTimeActionVisible() },
      { label: 'Koosta arve', visible: this.orderServiceStatus === OrderServiceStatus.PRESENT },
      { label: 'Ava MIS reg', visible: !!this.patientIdCode },
      { label: 'Ava MISis', visible: !!this.patientMisCode },
      { label: 'Ava Labor', visible: this.openReferralLaborVisible() },
      { label: 'Ava SST', visible: true },
      { label: 'Lõpetatud', visible: this.orderServiceStatus !== OrderServiceStatus.FINISHED },
      { label: 'Tühista kohal', visible: this.orderServiceStatus === OrderServiceStatus.PRESENT },
      { label: 'Kuva ajalugu', visible: true, command: () => this.showPatientReservationHistory()},
      {
        label: this.nextOrderId ? 'Ava külastuse tellimus' : 'Lisa uus tellimus',
        visible: this.orderServiceStatus === OrderServiceStatus.PRESENT,
      },
      { label: 'Vabasta saatekiri', visible: this.releaseReferralVisible() }
    ];
  }

  private getOrderServiceStatus(): OrderServiceStatus | null | undefined {
    return this.time.orderServiceTimeStatus || this.time.orderServiceQueueStatus || this.time.orderServiceStatus;
  }

  private printActionVisible(): boolean {
    const statuses = [OrderServiceStatus.CANCELLED, OrderServiceStatus.FINISHED, OrderServiceStatus.ABSENCE];

    return (
      !!this.orderServiceData &&
      !!this.orderServiceData.orderServiceTimes &&
      this.orderServiceData.orderServiceTimes.length > 0 &&
      this.orderServiceData.orderServiceTimes.some(ost => ost.orderServiceTimeStatus && !statuses.includes(ost.orderServiceTimeStatus))
    ) || (
      !!this.orderServiceData &&
      !!this.orderServiceData.orderServiceQueues &&
      this.orderServiceData.orderServiceQueues.length > 0 &&
      this.orderServiceData.orderServiceQueues.some(osq => osq.orderServiceTimeStatus && !statuses.includes(osq.orderServiceTimeStatus))
    );
  }

  private bookTimeActionVisible(): boolean {
    const todayDate = new Date();
    const currentTime = todayDate.toTimeString().split(' ')[0];
    const bookingDate = new Date(this.time.date);
    const bookingTimeStart = this.time.timeFrom;
    const statuses = [OrderServiceStatus.CONFIRMED, OrderServiceStatus.IN_PROVIDER_QUEUE, OrderServiceStatus.PREPARING, OrderServiceStatus.ABSENCE];
    const isSameDay = todayDate.toDateString() === bookingDate.toDateString();
    const bookingInFuture = isSameDay ? currentTime < bookingTimeStart : todayDate < bookingDate;

    return !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus) && bookingInFuture;
  }

  private openReferralsActionVisible(): boolean {
    const statuses = [
      OrderServiceStatus.CONFIRMED,
      OrderServiceStatus.PREPARING,
      OrderServiceStatus.PRESENT,
      OrderServiceStatus.IN_PROVIDER_QUEUE,
      OrderServiceStatus.IN_SERVICE_QUEUE,
      OrderServiceStatus.BOOKING_NOT_NEEDED,
      OrderServiceStatus.FINISHED
    ];
    return !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus);
  }

  private cancelTimeActionVisible(): boolean {
    const statuses = [
      OrderServiceStatus.CONFIRMED,
      OrderServiceStatus.PREPARING,
      OrderServiceStatus.IN_PROVIDER_QUEUE,
      OrderServiceStatus.IN_SERVICE_QUEUE
    ];

    return !!this.time && !!this.orderServiceStatus && statuses.includes(this.orderServiceStatus);
  }

  private openReferralLaborVisible(): boolean {
    return !!this.service && !!this.service.referralType && this.orderService.referralStatus === OrderReferralStatus.CONFIRMED && !!this.orderService.referralId;
  }

  private releaseReferralVisible(): boolean {
    if (!this.time) return false;

    const timeParts = this.time.timeUpto.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    const date = new Date(this.time.date);
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);
    date.setSeconds(date.getSeconds() + seconds);

    return date < new Date() && this.orderServiceStatus === OrderServiceStatus.CONFIRMED && this.orderService?.referralId !== null;
  }

  private filterInvoiceLogs(logs: IOrderServiceLog[]): IOrderServiceLog[] {
    return logs.filter(log => log.statusField === this.OrderServiceLogStatus.INVOICE_STATUS);
  }
  private filterOrderServiceLogs(logs: IOrderServiceLog[]): IOrderServiceLog[] {
    return logs.filter(log => log.statusField === this.OrderServiceLogStatus.STATUS || log.statusField === this.OrderServiceLogStatus.REFERRAL_STATUS);
  }

  showPatientReservationHistory() {
    const paramsOrderServiceId = new HttpParams().set('orderServiceId', this.time.orderServiceId);
    forkJoin({
      orderServiceLogs: this.orderServiceLog.getOrderServiceLogs(paramsOrderServiceId),
      orderServiceNotificationLogs: this.orderServiceLog.getOrderServiceNotificationLogs(paramsOrderServiceId)
    }).subscribe({
      next: (response) => {
        const orderServiceLogs = this.filterOrderServiceLogs(response.orderServiceLogs);
        const orderServiceInvoiceLogs = this.filterInvoiceLogs(response.orderServiceLogs);
        const orderServiceNotificationLogs = Array.isArray(response.orderServiceNotificationLogs) ? response.orderServiceNotificationLogs : [response.orderServiceNotificationLogs];
        this.modalService.openModal({
          component: ModalTableComponent,
          size: ModalSize.MEDIUM,
          data: {
            orderServiceLogs,
            orderServiceInvoiceLogs,
            orderServiceNotificationLogs,
          },
          header: `${this.time.serviceName} - staatuse ajalugu (tellimuse nr. ${this.time.orderId})`
        });
      },
      error: (e: Error) => {
        console.error(e);
      }
    });
  }

  protected readonly OrderServiceLogStatus = OrderServiceLogStatus;
}
