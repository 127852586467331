export enum OrderReferralStatus {
  OPTIONAL = 'OPTIONAL',
  MISSING = 'MISSING',
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  ASSIGNED = 'ASSIGNED',
  RESOLVED = 'RESOLVED',
  CANCELLED = 'CANCELLED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  EXPIRED = 'EXPIRED'
}
