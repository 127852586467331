import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { IGeneralList } from "../interfaces/general-list.interface";
import { IGeneralListType } from "../interfaces/general-list-type.interface";
import { IGeneralListAdd } from "@core/interfaces/general-list-add.interface";

@Injectable({
  providedIn: 'root'
})
export class ListService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;
  readonly listCodesMap = new BehaviorSubject<{ [code: string]: any }>({});

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/lists`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/lists`;
  }

  public getByType(typeId: number): Observable<IGeneralList[]> {
    return this.http.get<IGeneralList[]>(`${this.OLD_BACKEND_URL}/withtype/${typeId}`);
  }

  // public getAll(): Observable<IGeneralList[]> {
  //   return this.http.get<IGeneralList[]>(`${this.BACKEND_URL}/generallistswithcode`);
  // }

  public getChildProviderGroupsQuery(params: HttpParams): Observable<IGeneralList[]> {
    return this.http.get<IGeneralList[]>(`${this.BACKEND_URL}/getChildProviderGroupsQuery`, {params});
  }

  public getByParent(parentId: number, typeId: number): Observable<IGeneralList[]> {
    return this.http.get<IGeneralList[]>(`${this.BACKEND_URL}/withparent/${parentId}/${typeId}`);
  }

  public getTypes(): Observable<IGeneralListType[]> {
    return this.http.get<IGeneralListType[]>(`${this.BACKEND_URL}/types`);
  }

  public save(generalList: IGeneralListAdd): Observable<IGeneralList> {
    return this.http.post<IGeneralList>(`${this.OLD_BACKEND_URL}`, generalList);
  }

  public getByCode(code: string): Observable<IGeneralList> {
    return this.http.get<IGeneralList>(`${this.BACKEND_URL}/code/${code}`);
  }

  public getHolidays(): Observable<any[]> {
    return this.http.get<any[]>(`${this.BACKEND_URL}/holidays`);
  }

  // public getAllAndMap(): void {
  //   this.getAll().subscribe({
  //     next: (response) => {
  //       this.mapGeneralListsWithCode(response);
  //     },
  //     error: (err) => {
  //       console.error(err);
  //     }
  //   });
  // }

  mapGeneralListsWithCode(generalLists: IGeneralList[]): void {
    const listCodesMap: { [code: string]: any } = {};

    generalLists.forEach((generalList) => {
      if (generalList.code) {
        listCodesMap[generalList.code] = generalList.generalListId;
      }
    });

    this.listCodesMap.next(listCodesMap);
  }
}
