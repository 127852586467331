import {Component, Input, OnInit} from '@angular/core';
import {OfficeMenuItem} from "@core/enums/office-menu-item.enum";
import {MenuItem} from "primeng/api";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "@environments/environment";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";
import { ExternalReferralService } from "@core/services/external-referral.service";

@Component({
  selector: 'medis-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  @Input() sidebarOpen!: boolean;

  menuItemsTop: MenuItem[] = [];
  menuItemsBottom: MenuItem[] = [];
  activeItem: MenuItem | undefined;

  constructor(
    private router: Router,
    private externalMedicalCaseService: ExternalMedicalCaseService,
    private externalReferralService: ExternalReferralService,
  ) {}

  ngOnInit() {
    this.menuItemsTop = [
      { id: OfficeMenuItem.DAILY_PLAN, label: 'Töölaud', icon: 'pi pi-table', routerLink: '/office' },
      { id: OfficeMenuItem.ORDERS, label: 'Tellimused', icon: 'pi pi-list', routerLink: '/orders' },
      // { id: OfficeMenuItem.WORK_TABLE, label: 'Töölaud', icon: 'pi pi-desktop', routerLink: '/office' },
      { id: OfficeMenuItem.DOCTORS, label: 'Osutajad', icon: 'pi pi-users', routerLink: '/doctors' },
      { id: OfficeMenuItem.EXTERNAL_DOCTORS, label: 'Välised Osutajad', icon: 'pi pi-globe', routerLink: '/doctors/external' },
      { id: OfficeMenuItem.SERVICES, label: 'Teenused', icon: 'pi pi-bars', routerLink: '/services' },
      { id: OfficeMenuItem.PRODUCTS, label: 'Tooted', icon: 'pi pi-box', routerLink: '/products' },
      { id: OfficeMenuItem.SPECIAL_SERVICES, label: 'Eriteenused', icon: 'pi pi-star', routerLink: '/services/special' },
      { id: OfficeMenuItem.SPECIAL_SERVICES, label: 'Erialade grupid', icon: 'pi pi-users', routerLink: '/services/speciality-groups' },
      { id: OfficeMenuItem.DISCOUNTS, label: 'Allahindlused', icon: 'pi pi-percentage', routerLink: '/products/discounts' },
      { id: OfficeMenuItem.ROOMS, label: 'Ruumid', icon: 'pi pi-building', routerLink: '/rooms' },
      { id: OfficeMenuItem.DEVICES, label: 'Aparaadid', icon: 'pi pi-sitemap', routerLink: '/products/devices' },
      { id: OfficeMenuItem.FAMILY_DOCTORS, label: 'PA nimistud', icon: 'pi pi-list', routerLink: '/doctors/family' },
      { id: OfficeMenuItem.E_CONSULTATIONS, label: 'E-konsultatsioonid', icon: 'pi pi-id-card', routerLink: '/e-consultations' },
    ];
    this.menuItemsBottom = [
      { id: OfficeMenuItem.DISEASE_CASE, label: 'Haigusjuht', icon: 'icon icon-scop', command: () => this.externalMedicalCaseService.openMedicalCaseForm() },
      { id: OfficeMenuItem.REHABILITATION, label: 'Taastusravi', icon: 'pi pi-heart', command: () => this.externalMedicalCaseService.openPhysiotherapyForm() },
      { id: OfficeMenuItem.SAMPLER, label: 'Proovivõtja', icon: 'icon icon-tube', command: () => this.externalReferralService.openReferralLaborForm(0) },
      { id: OfficeMenuItem.STUDIES, label: 'Uuringud', icon: 'pi pi-search', command: () => this.externalMedicalCaseService.openStudyForm() },
      { id: OfficeMenuItem.PROCEDURES, label: 'Protseduurid', icon: 'icon icon-med', command: () => this.externalMedicalCaseService.openProceduresForm() },
    ];

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActiveItemBasedOnRoute();
      }
    });
  }

  private setActiveItemBasedOnRoute() {
    const currentRoute = this.router.url;
    const allMenuItems = [...this.menuItemsTop, ...this.menuItemsBottom].sort((a, b) => {
      return (b.routerLink?.length || 0) - (a.routerLink?.length || 0);
    });
    this.activeItem = allMenuItems.find(item => currentRoute === item.routerLink || currentRoute.startsWith(item.routerLink));
  }

  setActiveItem(item: MenuItem) {
    this.activeItem = item;
  }

  isActiveItem(item: MenuItem): boolean {
    return this.activeItem === item;
  }
}

