@if (time) {
  <p-button
    icon="pi pi-ellipsis-h"
    [outlined]="true"
    severity="secondary"
    [iconPos]="'right'"
    (click)="overlayPanel.toggle($event)"
  >
  </p-button>
}
<p-overlayPanel #overlayPanel>
  <p-menu styleClass="more-menu" [model]="actions"></p-menu>
</p-overlayPanel>

