import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from "@core/services/auth.service";

@Component({
  selector: 'app-dept-menu',
  templateUrl: './dept-menu.component.html',
  styleUrls: ['./dept-menu.component.scss'],
})
export class DeptMenuComponent {
  @Input() selectedProfession: string = '';
  @Input() selectedDepartment: string = '';
  @Input() departments: any[] = [];
  @Output() departmentChanged = new EventEmitter<{ department: string, profession: string }>();
  @Output() menuToggled = new EventEmitter<any>();

  constructor(private authService: AuthService,) {
  }

  isMenuActive: boolean = false;

  toggleMenu(event: any, overlayPanel: any) {
    this.isMenuActive = !this.isMenuActive;
    this.menuToggled.emit(this.isMenuActive);
    overlayPanel.toggle(event);
  }

  changeDepartment(department: string, profession: string) {
    this.departmentChanged.emit({ department, profession });
  }

  onMenuHide() {
    this.isMenuActive = false;
  }

  isSelected(dep: any): boolean {
    return dep.department === this.selectedDepartment && dep.profession === this.selectedProfession;
  }

  logout() {
    this.authService.logout();
  }
}
