import { Component, Input, OnInit } from '@angular/core';
import { ServiceType } from "@core/enums/service-type";
import { ServiceTypePipe } from "../../pipes/service-type.pipe";

@Component({
  selector: 'medis-service-type-label',
  standalone: true,
  imports: [
    ServiceTypePipe
  ],
  template: `
    <small class="bs-label p-2"
           [class]="badgeClass">
      {{type | serviceType}}
    </small>
  `
})
export class ServiceTypeLabelComponent implements OnInit {
  @Input() type!: ServiceType | null | undefined;
  badgeClass!: string;

  ngOnInit() {
    this.badgeClass = this.type && [ServiceType.IN_COMBO_ONLY, ServiceType.COMBO_SERVICE].includes(this.type) ? 'badge badge-info' : 'badge badge-warning';
  }
}
