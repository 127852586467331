import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { ServiceService } from "@core/services/service.service";
import { IService } from "@core/interfaces/service.interface";
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-service-picker',
  templateUrl: './service-picker.component.html',
  styleUrl: './service-picker.component.scss'
})
export class ServicePickerComponent implements OnInit {
  @Input() formSubmitted = false;
  @Input() required = false;
  @Input() selectedServiceIds: number[] = [];
  @Input() params!: HttpParams;

  @Output() selectedServiceIdsChange = new EventEmitter<number[]>();

  sourceServices: IService[] = [];
  targetServices: IService[] = [];

  constructor(
    private serviceService: ServiceService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getServices();
  }

  private getServices(): void {
    this.serviceService.getServicesForSelect(this.params).subscribe({
      next: services => {
        if (this.selectedServiceIds.length > 0) {
          this.sourceServices = services.filter(service => !this.selectedServiceIds.includes(service.serviceId));
          this.targetServices = services.filter(service => this.selectedServiceIds.includes(service.serviceId));
        } else {
          this.sourceServices = services;
        }
        this.cdr.markForCheck();
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  onTargetChange(event: any) {
    this.selectedServiceIds = this.targetServices.map(s => s.serviceId);
    this.selectedServiceIdsChange.emit(this.selectedServiceIds);
    this.cdr.markForCheck();
  }
}
