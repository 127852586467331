import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IAbsence } from "../interfaces/absence.interface";
import { IAbsenceLog } from "../interfaces/absence-log.interface";
import { IAbsenceSubstituteLog } from "../interfaces/absence-substitution-log.interface";
import { ICopyPlanTimeData } from "../interfaces/copy-plan-time-data.interface";
import { IPlanTime } from "../interfaces/plan-time.interface";
import { IRoom } from "../interfaces/room.interface";
import { IAbsenceSubstitute } from "../interfaces/absence-substitute.interface";
import { IBookingTime } from "../interfaces/booking-time.interface";
import { IOrderServiceQueue } from "../interfaces/order-service-queue.interface";
import { IAddress } from "../interfaces/address.interface";
import { IPlanTimeLog } from "../interfaces/plan-time-log.interface";

@Injectable({
  providedIn: 'root'
})
export class RosterService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/rosters`;
  }

  public saveAbsence(absence: IAbsence): Observable<boolean> {
    return this.http.post<boolean>(`${this.BACKEND_URL}/absence/save`, absence);
  }

  public getAbsenceLogs(personId: number): Observable<IAbsenceLog[]> {
    return this.http.get<IAbsenceLog[]>(`${this.BACKEND_URL}/absence/logs/${personId}`);
  }

  public getActiveAbsenceLogs(personId: number): Observable<IAbsenceLog[]> {
    return this.http.get<IAbsenceLog[]>(`${this.BACKEND_URL}/absence/logs/${personId}/active`);
  }

  public getAbsenceSubstitutionsLogs(personId: number): Observable<IAbsenceSubstituteLog[]> {
    return this.http.get<IAbsenceSubstituteLog[]>(`${this.BACKEND_URL}/substitutions/logs/${personId}`);
  }

  public copyPlanTime(planTime: ICopyPlanTimeData): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/plantimes/copy`, planTime);
  }

  public createPlanTime(planTime: ICopyPlanTimeData): Observable<IPlanTime> {
    return this.http.post<IPlanTime>(`${this.BACKEND_URL}/planTimes/save`, planTime);
  }

  public deleteAll(params: HttpParams): Observable<any> {
    return this.http.delete<any>(`${this.BACKEND_URL}/plantimes/deleteall`, {params});
  }

  public updateStatus(status: number, params: HttpParams): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/updateStatus/${status}`, {}, {params});
  }

  public deleteAbsence(absenceId: number): Observable<any> {
    return this.http.delete<any>(`${this.BACKEND_URL}/absence/delete/${absenceId}`);
  }

  public getAbsences(personId: number): Observable<IAbsence[]> {
    return this.http.get<IAbsence[]>(`${this.BACKEND_URL}/absences/${personId}`);
  }

  public getRoom(roomId: number): Observable<IRoom> {
    return this.http.get<IRoom>(`${this.BACKEND_URL}/rooms/${roomId}`);
  }

  public getSubstitutions(personId: number): Observable<IAbsenceSubstitute[]> {
    return this.http.get<IAbsenceSubstitute[]>(`${this.BACKEND_URL}/substitutions/${personId}`);
  }

  public getTimes(personId: number, personServiceId: number, date: string): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/times/${personId}/${personServiceId}/${date}`);
  }

  public getQueues(personId: number, personServiceId: number, date: string): Observable<IOrderServiceQueue[]> {
    return this.http.get<IOrderServiceQueue[]>(`${this.BACKEND_URL}/queues/${personId}/${personServiceId}/${date}`);
  }

  public getPlanTime(planTimeId: number): Observable<IPlanTime> {
    return this.http.get<IPlanTime>(`${this.BACKEND_URL}/planTimes/${planTimeId}`);
  }

  public getPlanTimeByTime(params: HttpParams): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/plantime`, {params});
  }

  public getPlanTimes(personId: number, startDate: string, endDate: string, params: HttpParams): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/plantimes/${personId}/${startDate}/${endDate}`, {params});
  }

  public getAddresses(serviceId: number, params: HttpParams): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(`${this.BACKEND_URL}/addresses/${serviceId}`, {params});
  }

  public getPlanTimesByAbsenceReason(personId: number, absenceReasonId: number, startDate: string): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/plantimes/${personId}/${absenceReasonId}/${startDate}`);
  }

  public getPlanTimesByRoom(personId: number, startDate: string, endDate: string, serviceCategoryId: number, params: HttpParams): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/rooms/plantimes/${personId}/${startDate}/${endDate}/${serviceCategoryId}`, {params});
  }

  public getPlanTimesByService(personId: number, startDate: string, endDate: string, serviceId: number): Observable<IPlanTime[]> {
    return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/plantimes/${personId}/${startDate}/${endDate}/${serviceId}`);
  }

  public getPlanTimeLogs(providerId: number, date: string): Observable<IPlanTimeLog[]> {
    return this.http.get<IPlanTimeLog[]>(`${this.BACKEND_URL}/plantimes/${providerId}/${date}/logs`);
  }

  public getPlanTimeTypes(): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/plantimetypes`);
  }

  public deleteRelatedPersonPlanTimesByAbsenceId(absenceId: number): Observable<any> {
    return this.http.delete<any>(`${this.BACKEND_URL}/absence/${absenceId}/relatedPersonPlanTimes`);
  }
}
