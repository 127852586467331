import { Component, Input, OnInit } from '@angular/core';
import { IFullOrder } from "@core/interfaces/full-order.interface";
import { getChannelName } from "@core/enums/channel-id.enum";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderServiceService } from "@core/services/order-service.service";

@Component({
  selector: 'medis-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit{
  @Input() data: IFullOrder[] = [];
  expandedRows: { [key: string]: boolean } = {};

  constructor(private orderServiceService: OrderServiceService) {}

  ngOnInit() {
    this.data.forEach(order => {
      if (order.orderService.orderServiceTimes && order.orderService.orderServiceTimes.length > 0) {
        this.expandedRows[order.order.orderId] = true;
      }
    });
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.order.orderId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.order.orderId];
  }

  getChannelName(channelId: number): string {
    return getChannelName(channelId);
  }

  openCommentsByOrder = (orderServiceTime: IOrderServiceTime | null) => {
    this.orderServiceService.openCommentsByOrder(orderServiceTime);
  };

}
