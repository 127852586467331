import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IReferral } from "../interfaces/referral.interface";
import { IOrderServicePayment } from "../interfaces/order-service-payment.interface";
import { IOrderServiceVisitPriceInfo } from "../interfaces/order-service-visit-price-info.interface";
import { IUpdateOrderServiceDiscountRequest } from "../interfaces/update-order-service-discount-request.interface";

@Injectable({
  providedIn: 'root'
})
export class OrderServiceService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/orderservices`;
  }

  public deleteReferral(orderServiceId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.BACKEND_URL}/referral/remove/${orderServiceId}`);
  }

  public searchQueues(params: HttpParams): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/search/queues`, {params});
  }

  public getReferral(orderServiceId: number): Observable<IReferral> {
    return this.http.get<IReferral>(`${this.BACKEND_URL}/referral/${orderServiceId}`);
  }

  public getUnpaidServices(patientId: number): Observable<IOrderServicePayment[]> {
    return this.http.get<IOrderServicePayment[]>(`${this.BACKEND_URL}/unpaid/${patientId}`);
  }

  public cancelService(orderServiceId: number): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/cancel/${orderServiceId}`, {});
  }

  public getUnpaidOrderService(orderServiceId: number): Observable<IOrderServicePayment> {
    return this.http.get<IOrderServicePayment>(`${this.BACKEND_URL}/unpaidOrderService/${orderServiceId}`);
  }

  public getVisitPriceInfo(orderServiceId: number): Observable<IOrderServiceVisitPriceInfo> {
    return this.http.get<IOrderServiceVisitPriceInfo>(`${this.BACKEND_URL}/visitPriceInfo/${orderServiceId}`);
  }

  public saveVisitPriceInfo(visitPriceInfo: IOrderServiceVisitPriceInfo): Observable<boolean> {
    return this.http.post<boolean>(`${this.BACKEND_URL}/saveVisitPriceInfo`, visitPriceInfo);
  }

  public deleteVisitPriceInfo(orderServiceId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.BACKEND_URL}/deleteVisitPriceInfo/${orderServiceId}`);
  }

  public updateDiscount(updateData: IUpdateOrderServiceDiscountRequest): Observable<boolean> {
    return this.http.post<boolean>(`${this.BACKEND_URL}/updateDiscount`, updateData);
  }
}
