import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from "@angular/common";

@Pipe({
  name: 'dateTimeDisplay',
  standalone: true
})
export class DateTimeDisplayPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {
  }

  transform(date: string | Date | undefined, format: string = 'dd.MM.yyyy HH:mm'): string {
    if (date) {
      const inputDate = new Date(date);
      return this.datePipe.transform(inputDate, format) ?? '';
    }
    return '';
  }

}
