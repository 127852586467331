import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(datetime: string | Date | null | undefined): string {
    if (!datetime) return '';

    const dateValue = datetime instanceof Date ? datetime : new Date(datetime);
    return this.datePipe.transform(dateValue, 'dd.MM.yyyy') ?? '';
  }
}
