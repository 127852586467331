import { Component, Input, OnInit } from '@angular/core';
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IPerson } from "@core/interfaces/person.interface";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'medis-colleague-info-popover',
  standalone: true,
  imports: [
    NgbPopover
  ],
  templateUrl: './colleague-info-popover.component.html',
  styleUrl: './colleague-info-popover.component.less'
})
export class ColleagueInfoPopoverComponent implements OnInit {
  @Input() orderService!: IOrderServiceTime;
  relatedPerson!: IPerson;

  ngOnInit() {
    if(this.orderService){
      this.relatedPerson = this.orderService.relatedPerson;
    }
  }
}
