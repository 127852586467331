import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IInvoice } from "../interfaces/invoice.interface";
import { IOrderServicePayment } from "../interfaces/order-service-payment.interface";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/invoices`;
  }

  public createInvoice(orderId: number): Observable<string[]> {
    return this.http.post<string[]>(`${this.BACKEND_URL}/create/${orderId}`, {});
  }

  public createPresentInvoice(orderServiceId: number): Observable<{ invoiceNumber: string }> {
    return this.http.post<{ invoiceNumber: string }>(`${this.BACKEND_URL}/create/${orderServiceId}/orderService`, {});
  }

  public createOrderServicesInvoice(params: HttpParams): Observable<string[]> {
    return this.http.post<string[]>(`${this.BACKEND_URL}/create/orderServices`, null,{params});
  }

  public get(invoiceNumber: string): Observable<{ link: string }> {
    return this.http.get<{ link: string }>(`${this.BACKEND_URL}/${invoiceNumber}/link`);
  }

  public getInvoiceData(orderServiceId: number): Observable<IInvoice> {
    return this.http.get<IInvoice>(`${this.BACKEND_URL}/${orderServiceId}/data`);
  }

  public updateInvoiceData(orderServiceId: number): Observable<IInvoice> {
    return this.http.get<IInvoice>(`${this.BACKEND_URL}/${orderServiceId}/update`);
  }

  public getClientInvoices(patientId: number , params: HttpParams): Observable<IOrderServicePayment[]> {
    return this.http.get<IOrderServicePayment[]>(`${this.BACKEND_URL}/list/${patientId}`, {params});
  }

  public reCreatePrepaidInvoice(orderServiceId: number): Observable<string> {
    return this.http.post<string>(`${this.BACKEND_URL}/create/${orderServiceId}/prepayment`, {});
  }
}
