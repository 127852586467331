<p-table [value]="data" dataKey="order.orderId" [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 7.5%">Tellimus</th>
      <th style="width: 14%">Patsient</th>
      <th style="width: 10.5%">Osutaja</th>
      <th style="width: 6%">Maksja</th>
      <th style="width: 22.5%">Teenus</th>
      <th style="width: 10.5%">Staatus</th>
      <th style="width: 6.5%">Kanal</th>
      <th style="width: 10.5%">Saatekiri</th>
      <th style="width: 8.5%">Suunaja</th>
      <th style="width: 3.5%"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order let-expanded="expanded">
    <tr>
      <td>
        <div class="flex align-items-center">
          @if(order.orderService.orderServiceTimes?.length > 0){
            <p-button
              type="button"
              pRipple
              [pRowToggler]="order"
              [text]="true"
              [rounded]="true"
              [plain]="true"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></p-button>
          } @else {
            <div style="width: 33px; height: 33px;"></div>
          }
          <div class="ml-2 gap-1">
            <p class="m-0">{{ order.orderService.orderId }}</p>
            <span class="text-base font-semibold">{{ order.orderService.created | dateFormat }}</span>
          </div>
        </div>
      </td>
      <td>
        <div class="flex flex-column gap-1">
          <a class="text-primary patient-link">{{ order.order.patient.fullName }}</a>
          <span class="text-base font-semibold">{{ order.order.patient.idCode }}</span>
        </div>
      </td>
      <td>
        <p>
          {{
            order.orderService.orderServiceTimes[0]?.providerName
              ? order.orderService.orderServiceTimes[0]?.providerName
              : order.orderService.providerName
                ? order.orderService.providerName
                : order.orderService.orderServiceQueues[0]?.providerName
          }}
        </p>

      </td>
      <td>
        @if(order.orderService.financialSourceName){
          <medis-financial-source-label [financialSource]="order.orderService.financialSourceName"></medis-financial-source-label>
        }
      </td>
      <td>
        <div class="flex gap-2 align-items-center">
          <medis-service-badge [badges]="order.service.serviceBadges"></medis-service-badge>
          {{ order.service.name }}
          @if (order.orderService.commentsCount && order.orderService.commentsCount > 0) {
            <p-button [rounded]="true"
                      icon="pi pi-comment"
                      [text]="true"
                      severity="secondary"
                      (click)="openCommentsByOrder(order.orderService)"/>
          }
        </div>
      </td>
      <td>
        @if (order.orderService.orderServiceTimeStatus || order.orderService.orderServiceStatus) {
          <medis-order-service-status-label [status]="order.orderService.orderServiceTimeStatus ?? order.orderService.orderServiceStatus"
                                            [invoiceStatus]="order.orderService.invoiceStatus">
          </medis-order-service-status-label>
        }
      </td>
      <td>
        {{ getChannelName(order.order.channelId) }}
      </td>
      <td>
        <div class="flex flex-column gap-1">
          @if(order.orderService.referralStatus !== "NotAllowed"){
            <medis-referral-status-label [referralStatus]="order.orderService.referralStatus"></medis-referral-status-label>
          }
          <span class="text-base font-semibold">{{ order.orderService.referralNumber }}</span>
        </div>
      </td>
      <td>
        <div class="flex flex-column">
          <p class="m-0">{{ order.orderService.referralAuthorName }}</p>
          <p class="m-0">{{ order.orderService.referralSourceCompanyName }}</p>
        </div>
      </td>
      <td>
        <div class="flex justify-content-end">
          <p-button
            icon="pi pi-ellipsis-h"
            [outlined]="true"
            severity="secondary"
            [iconPos]="'right'"
          >
          </p-button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-order>
    <tr class="border-0">
      <td colspan="10" class="p-0 border-0">
          <p-table [value]="[{}]" dataKey="orderServiceTimeId">
            <ng-template pTemplate="body" let-orderServiceTime>
              @for (orderServiceTime of order.orderService.orderServiceTimes; track $index){
                <tr class="border-0">
                  <td style="width: 7.5%"></td>
                  <td style="width: 14%"></td>
                  <td style="width: 10.5%"></td>
                  <td style="width: 6%"></td>
                  <td style="width: 22.5%">
                    Aeg {{ $index + 1 }} - {{ orderServiceTime?.date | dateFormat }} &nbsp;
                    {{ orderServiceTime?.timeFrom | timeFormat }} - {{ orderServiceTime?.timeUpto | timeFormat}} &nbsp;
                    {{ orderServiceTime?.roomCode }} &nbsp;
                    {{ orderServiceTime?.departmentShort }}
                  </td>
                  <td style="width: 10.5%">
                    @if (orderServiceTime?.orderServiceTimeStatus || orderServiceTime?.orderServiceStatus) {
                      <medis-order-service-status-label [status]="orderServiceTime?.orderServiceTimeStatus ?? orderServiceTime?.orderServiceStatus"
                                                        [invoiceStatus]="orderServiceTime?.invoiceStatus">
                      </medis-order-service-status-label>
                    }
                  </td>
                  <td style="width: 6.5%"></td>
                  <td style="width: 10.5%"></td>
                  <td style="width: 8.5%"></td>
                  <td style="width: 3.5%"></td>
                </tr>
              }
            </ng-template>
          </p-table>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="10">Midagi pole leitud.</td>
    </tr>
  </ng-template>
</p-table>
